import moment from 'moment'

export default class DateUtil {
  /**
   * Converts a date Object into a string in the format of 'YYYY-MM-DD HH:MM:SS'
   * for used in a Postgres query. The date is converted to UTC time.
   *
   * Note, if the Date object already have a timezone, it may return a different date due to
   * the conversion to UTC.
   *
   * Note, if this is used in a query, the timestamp column should be truncated to
   * the seconds, since javascript Date object does not have enough precision to
   * match the timestamp column, if the timestamp column has milliseconds.
   *
   * Warning, be careful of timezones. The Date object is in the local timezone, but
   * the Postgres timestamp column maybe in UTC. Verify the data type in the table.
   *
   * @param date A javascript Date object
   */
  static getUTCPostgresString(date: Date): string {
    const pad = (number: number) => (number < 10 ? '0' + number : number)

    let year = date.getUTCFullYear()
    let month = pad(date.getUTCMonth() + 1) // Months are zero-indexed
    let day = pad(date.getUTCDate())
    let hours = pad(date.getUTCHours())
    let minutes = pad(date.getUTCMinutes())
    let seconds = pad(date.getUTCSeconds())

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  // Get string in format of 'YYYY-MM-DD HH:MM:SS'
  static getDateTimeString(date: Date | string) {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }

  // Get string in format Aug 12, 2019
  static getDateString(date: Date | string, utc: boolean = false) {
    return utc ? moment(date).utc().format('MMM DD, YYYY') : moment(date).format('MMM DD, YYYY')
  }

  static formatDateTime(date: Date, utc: boolean = false) {
    return utc
      ? moment(date).format('MMM D, YYYY hh:mm A')
      : moment(date).utc().format('MMM D, YYYY hh:mm A')
  }

  // Get string for date ranges
  static getDateRangeString(start: Date, end: Date, utc: boolean = false) {
    // Within the same month (e.g. Aug 12 - 15, 2019)
    if (start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
      return `${start.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        timeZone: utc ? 'UTC' : undefined,
      })} - ${end.toLocaleDateString('en-US', {
        day: 'numeric',
        timeZone: utc ? 'UTC' : undefined,
      })}, ${end.getFullYear()}`
    }

    // Within the same year (e.g. Aug 12 - Sep 15, 2019)
    if (start.getFullYear() === end.getFullYear()) {
      return `${start.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        timeZone: utc ? 'UTC' : undefined,
      })} - ${end.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: utc ? 'UTC' : undefined,
      })}`
    }

    // Different years (e.g. Aug 12, 2019 - Sep 15, 2020)
    return `${DateUtil.getDateString(start, utc)} - ${DateUtil.getDateString(end, utc)}`
  }

  // Get string in format of 'YYYY-MM-DD'
  static getDateStringISO(date: Date | string) {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  }

  // Get the difference in days between two dates
  static getDifferenceInDays(date1: Date, date2: Date) {
    return moment(date2).diff(moment(date1), 'days')
  }

  // Add days to a date
  static addDays(date: Date, days: number) {
    return moment(date).add(days, 'days').toDate()
  }

  /**
   * Checks if the passed date is a valid JavaScript Date object
   * @param date A date object to be validated
   */
  static isValidDate(date: any): boolean {
    return date instanceof Date && date.getTime() > 0
  }

  /**
   * Calculate the time since a given date and format it in terms of seconds, minutes, hours, days, months, or years. For example, if the date was 3 weeks ago, should return "3 weeks".
   * @param date
   * @returns
   */
  static getTimeSince(date: Date): string {
    const now = new Date()

    // Calculate the differences in each unit
    let years = now.getFullYear() - date.getFullYear()
    let months = now.getMonth() - date.getMonth()
    let days = now.getDate() - date.getDate()

    // Adjust months and years if the day of the month causes an overflow
    if (days < 0) {
      months -= 1
      // Calculate the number of days in the previous month
      const previousMonth = new Date(now.getFullYear(), now.getMonth(), 0)
      days += previousMonth.getDate()
    }

    // Adjust the year if months go negative
    if (months < 0) {
      years -= 1
      months += 12
    }

    // Prioritize the largest time unit and return
    if (years > 0) {
      return `${years} ${years === 1 ? 'year' : 'years'} ago`
    } else if (months > 0) {
      return `${months} ${months === 1 ? 'month' : 'months'} ago`
    } else if (days > 0) {
      return `${days} ${days === 1 ? 'day' : 'days'} ago`
    } else {
      const diffInMs = now.getTime() - date.getTime()
      const seconds = Math.floor(diffInMs / 1000)
      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)

      if (hours > 0) {
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`
      } else if (minutes > 0) {
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`
      } else {
        return 'now'
      }
    }
  }
}
