import { Grid } from '@mui/material'
import ChartType from '../../../model/chart/ChartType'
import StandardChartData from '../../../model/chart/StandardChartData'
import EChart from '../Echart'
import { FIXED_HEIGHT, styles } from './styles'

export function LineChart({ data }: { data: StandardChartData }) {
  return (
    <Grid
      sx={{
        ...styles.columnFlex,
        ...FIXED_HEIGHT,
        borderBottom: '0.0625rem solid #e0e0e0',
        width: '100%',
      }}
    >
      <EChart data={data} type={ChartType.levelsFull} constrainedValues />
    </Grid>
  )
}
