import { useHookstate } from '@hookstate/core'
import { LoadingButton } from '@mui/lab'
import { Dialog, Divider, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import '../../components/modal/FileUploadController'
import FileUploadController from '../../components/modal/FileUploadController'
import copy from '../../config/copy.config'
import { useModal } from '../../contexts/ModalContext'
import { exploreManager, opportunityManager } from '../../managers/_manager.config'
import Opportunity from '../../model/opportunity/Opportunity'
import PropensityType from '../../model/propensity/PropensityType'
import { ScreenPath } from '../../navigation'
import globalState from '../../service/external/GlobalState'
import StringUtil from '../../util/StringUtil'
import PropensityTypeSelector from '../explore/PropensityTypeSelector'
import CustomTextFieldController from '../modal/CustomTextFieldController'
import ModalContent from '../modal/ModalContent'
import ModalHeader from '../modal/ModalHeader'
import AutocompleteSearchBar from '../util/AutocompleteSearchBar'
import StatusView from '../util/StatusView'

export interface UploadTargetListModalProps {
  onClose: () => void
}

export interface UploadTargetListModalForm {
  name: string
  productCategoryID: string
  propensityType: PropensityType
  file: File
}

export default function UploadTargetListModal({ onClose }: UploadTargetListModalProps) {
  // Navigation
  const navigate = useNavigate()
  const { showSnackbar } = useModal()

  // Global state
  const opportunitiesState = useHookstate(globalState.opportunities).get()
  const productCategoriesState = useHookstate(globalState.productCategories).get()

  // -- Local state
  const [memberIDs, setMemberIDs] = useState<string[]>([])
  const [saving, setSaving] = useState(false)

  // Form
  const { control, handleSubmit } = useForm<UploadTargetListModalForm>({ mode: 'onChange' })

  // -- Lifecycle
  useEffect(() => {
    exploreManager.fetchProductCategories()
  }, [])

  // Functions
  async function validateFile(file: File) {
    try {
      const memberIDs = await opportunityManager.readTargetList(file)
      setMemberIDs(memberIDs)
      return true
    } catch (error: any) {
      setMemberIDs([])
      showSnackbar('target-list-error', file ? error.message : 'Please attach a file', 'error', {
        vertical: 'top',
        horizontal: 'center',
      })
      return false
    }
  }

  // -- Actions
  async function onSubmit(data: UploadTargetListModalForm) {
    // Resetting the error message and loading state before submission
    setSaving(true)
    setMemberIDs([]) // Clear the memberIDs before submission attempt

    try {
      const opportunities = opportunitiesState.data as Opportunity[] | null
      if (!opportunities) {
        throw new Error('Could not fetch opportunities to validate name')
      }

      const nameError = Opportunity.validateName(data.name, opportunities)
      if (nameError) {
        throw new Error(nameError) // Custom error message handling
      }

      const memberIDs = await opportunityManager.readTargetList(data.file)
      const opportunity = await opportunityManager.createManualOpportunity(
        data.name,
        data.productCategoryID,
        data.propensityType,
        memberIDs,
      )

      // Reset saving state and navigate upon success
      setSaving(false)
      navigate(`${ScreenPath.opportunities}/${opportunity.opportunityID}`)
      onClose() // Close modal after success
    } catch (error: any) {
      // Handle any error and show the snackbar
      onError(error.message)
    }
  }

  function onError(error?: string) {
    // Set saving state to false after an error occurs
    setSaving(false)
    showSnackbar(
      'target-list-error',
      error ?? 'Make sure you have completed all required fields and uploaded a valid file',
      'error',
      {
        vertical: 'top',
        horizontal: 'center',
      },
    )
    setMemberIDs([]) // Clear the list of member IDs
  }

  // -- UI
  return (
    <Dialog open={true} onClose={onClose} maxWidth='sm' fullWidth>
      <ModalHeader
        title={copy.opportunity.upload.title}
        description={copy.opportunity.upload.description}
        center
      />
      <ModalContent>
        <StatusView
          state={productCategoriesState}
          render={(productCategories) => {
            return (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack gap={2}>
                  <CustomTextFieldController name='name' label='Name' control={control} />
                  <Stack direction='row' gap={1}>
                    <Controller
                      name='productCategoryID'
                      control={control}
                      render={({ field }) => (
                        <AutocompleteSearchBar
                          onSelect={(value) => field.onChange(value)}
                          placeholder='Select target product...'
                          options={productCategories.map((productCategory) => ({
                            label: productCategory.name,
                            value: productCategory.productCategoryID,
                          }))}
                        />
                      )}
                      rules={{ required: true }}
                    />
                    <Controller
                      name='propensityType'
                      control={control}
                      render={({ field }) => <PropensityTypeSelector {...field} />}
                      rules={{ required: true }}
                      defaultValue={PropensityType.growth}
                    />
                  </Stack>
                  <Divider />
                  <FileUploadController
                    name='file'
                    control={control}
                    optional={false}
                    placeholder='Upload a member list'
                    label='File'
                    rules={{
                      validate: (file: File) => validateFile(file),
                    }}
                    filetypes={['.csv']}
                    customAdornment={() =>
                      memberIDs.length === 0
                        ? ''
                        : `Found ${StringUtil.numberFormat(memberIDs.length)} members`
                    }
                  />

                  <LoadingButton
                    onClick={handleSubmit(onSubmit, () => onError())}
                    variant='contained'
                    sx={{ mt: 5 }}
                    loading={saving}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </form>
            )
          }}
        />
      </ModalContent>
    </Dialog>
  )
}
