import { Chip, alpha } from '@mui/material'
import { OpportunityStatus } from '../../model/opportunity/Opportunity'
import StringUtil from '../../util/StringUtil'

export interface StatusChipProps {
  status: OpportunityStatus
}

export const statusColors = {
  [OpportunityStatus.draft]: '#3B3F42',
  [OpportunityStatus.pending]: '#F79C2C',
  [OpportunityStatus.published]: '#0B46B4',
  [OpportunityStatus.active]: '#5B19A5',
  [OpportunityStatus.evaluating]: '#2AA198', 
  [OpportunityStatus.completed]: '#139B29',
}

export default function StatusChip({ status }: StatusChipProps) {
  return (
    <Chip
      label={StringUtil.capitalize(status)}
      sx={{
        color: statusColors[status],
        backgroundColor: alpha(statusColors[status], 0.1),
        fontWeight: 'bold',
      }}
    />
  )
}
