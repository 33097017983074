import { Box, Grid, Stack, Typography } from '@mui/material'
import copy from '../../../config/copy.config'
import InfoTag from '../../metric/InfoTag'
import { styles } from './styles'

interface TitleRowProps {}

export function TitleRow({}: TitleRowProps) {
  return (
    <Grid container>
      <Grid item xs={3.75} sx={{ ...styles.cellStyles, alignItems: 'flex-start' }}>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', marginLeft: 12 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight='bold'>Members Per Level</Typography>
            <InfoTag description={copy.dashboard.membersPerLevel.description} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3} sx={styles.cellStyles} flexDirection='column'>
        <Stack direction='row' justifyContent='center' alignItems='center' width='100%'>
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', marginLeft: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontWeight='bold'>Level Movement</Typography>
              <InfoTag description={copy.dashboard.levelsFull.description} />
            </Box>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={2} sx={{ ...styles.cellStyles, alignItems: 'flex-start' }}>
        <Typography fontWeight='bold'>Avg. Products and Services</Typography>
      </Grid>
      <Grid item xs={3.25} sx={{ ...styles.cellStyles, alignItems: 'flex-start' }}>
        <Stack direction='column' justifyContent='space-between' width={150} alignItems='center'>
          <Typography fontWeight='bold'># of Members</Typography>
          <Typography color='text.secondary' variant='body2' fontSize={12}>
            Past 6 Months
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}
