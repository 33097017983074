import { Icon } from '@iconify/react'
import { Button, Grid } from '@mui/material'
import icons from '../../../config/icons.config'
import { useModal } from '../../../contexts/ModalContext'
import FullLevelsChartData, { FullLevelData } from '../../../model/chart/FullLevelsChartData'
import { Timeframe } from '../../../model/chart/Timeframe'
import palette from '../../../theme/palette'
import StringUtil from '../../../util/StringUtil'
import { LevelMovementModal } from '../../dashboard/LevelMovementModal'
import { FIXED_HEIGHT, styles } from './styles'

interface MovementIndicatorProps {
  levelData: FullLevelData
  timeframe: Timeframe
}

export function MovementIndicator({ levelData, timeframe }: MovementIndicatorProps) {
  // -- Navigation
  const { openModal, closeModal } = useModal()

  // -- Logic
  const { memberChange, percentChange } = FullLevelsChartData.getOverallMovement(
    levelData,
    timeframe,
  )

  // -- Actions
  function onClick() {
    const id = 'level-movement-modal'
    openModal(
      id,
      <LevelMovementModal
        levelData={levelData}
        initialTimeframe={timeframe}
        onClose={() => closeModal(id)}
      />,
    )
  }

  // -- UI
  return (
    <Grid sx={{ ...styles.centeredFlex, ...styles.cellStyles, ...FIXED_HEIGHT }}>
      <Button
        color='inherit'
        startIcon={
          <Icon
            icon={memberChange >= 0 ? icons.movement.up : icons.movement.down}
            color={memberChange >= 0 ? palette.light.growth : palette.light.churn}
          />
        }
        onClick={onClick}
      >
        {StringUtil.numberFormat(Math.abs(memberChange))} (
        {StringUtil.numberFormat(Math.abs(percentChange), 1)}
        %)
      </Button>
    </Grid>
  )
}
