import { useHookstate } from '@hookstate/core'
import FullLevelsChartData from '../../../model/chart/FullLevelsChartData'
import globalState from '../../../service/external/GlobalState'
import { TableRows } from './TableRows'
import { TitleRow } from './TitleRow'

export function LevelsCard({ data }: { data: FullLevelsChartData }) {
  const timeframe = useHookstate(globalState.dashboardTimeframe).get()

  const values = data.levels.map((level) => level.memberCount)
  const maxValue = Math.max(...values)
  const normalizedValues = values.map((value) => value / maxValue)

  return (
    <div>
      <TitleRow />
      <TableRows data={data} normalizedValues={normalizedValues} timeframe={timeframe} />
    </div>
  )
}
