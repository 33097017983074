import React from 'react'
import ConfirmModal from '../../util/ConfirmModal'

interface DeleteModalProps {
  label: string
  messageOverride?: string
  onClose?: () => void
  onConfirm?: () => void
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  label,
  messageOverride,
  onClose,
  onConfirm,
}) => {
  return (
    <ConfirmModal
      title={`Delete ${label}`}
      message={messageOverride ?? `Are you sure you want to delete this ${label}?`}
      confirmation='Delete'
      onClose={onClose}
      onConfirm={onConfirm}
    />
  )
}

export default DeleteModal
