import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { Button, Chip, Divider, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import DeleteModal from '../components/admin/modals/DeleteModal'
import AIOpportunitiesBadge from '../components/opportunity/AIOpportunitiesBadge'
import AIOpportunitiesModal from '../components/opportunity/AIOpportunitiesModal'
import MergeOpportunitiesModal from '../components/opportunity/MergeOpportunitiesModal'
import OpportunitiesTable from '../components/opportunity/OpportunitiesTable'
import UploadTargetListModal from '../components/opportunity/UploadTargetListModal'
import DynamicMenu from '../components/util/DynamicMenu'
import SearchBar from '../components/util/SearchBar'
import SelectableButton from '../components/util/SelectableButton'
import StandardCard from '../components/util/StandardCard'
import StatusView from '../components/util/StatusView'
import icons from '../config/icons.config'
import { useModal } from '../contexts/ModalContext'
import { opportunityManager } from '../managers/_manager.config'
import Opportunity, {
  OpportunityAIStatus,
  OpportunityStatus,
} from '../model/opportunity/Opportunity'
import { ScreenPath } from '../navigation'
import globalState from '../service/external/GlobalState'
import StringUtil from '../util/StringUtil'

export default function OpportunitiesScreen() {
  // Navigation
  const { openModal, closeModal, showSnackbar } = useModal()
  const navigate = useNavigate()

  // Global state
  const opportunitiesState = useHookstate(globalState.opportunities).get()

  // -- Local state
  const [searchText, setSearchText] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState<OpportunityStatus | 'all'>('all')
  const [searchParams, setSearchParams] = useSearchParams()

  // Effect
  useEffect(() => {
    opportunityManager.fetchOpportunities()
  }, [])

  useEffect(() => {
    const urlStatus = searchParams.get('status')
    if (urlStatus) {
      setStatusFilter(urlStatus as OpportunityStatus)
    } else {
      searchParams.set('status', 'all')
      setSearchParams(searchParams)
    }
  }, [searchParams])

  // Functions
  function getRelevantOpportunities(opportunities: Opportunity[]) {
    return opportunities.filter((opportunity) => {
      return opportunity.isUsable()
    })
  }

  function getFilteredOpportunities(opportunities: Opportunity[]) {
    const filtered = getRelevantOpportunities(opportunities)
      .filter((opportunity) => {
        if (statusFilter === 'all') return true
        return opportunity.status === statusFilter
      })
      .filter((opportunity) => {
        if (searchText === '') return true
        return StringUtil.containsIgnoreCase(opportunity.name, searchText)
      })

    const sorted = filtered.sort((a, b) => {
      const aOrder = Object.values(OpportunityStatus).indexOf(a.status)
      const bOrder = Object.values(OpportunityStatus).indexOf(b.status)
      return aOrder - bOrder
    })
    return sorted
  }

  // -- Actions
  function onStatusFilterChange(status: OpportunityStatus | 'all') {
    searchParams.set('status', status)
    searchParams.set('page', '0')
    setSearchParams(searchParams)
  }

  function onAIOpportunitiesClick() {
    openModal(
      'ai-opportunities',
      <AIOpportunitiesModal onClose={() => closeModal('ai-opportunities')} />,
    )
  }

  function onViewOpportunity(opportunityID: string, opportunities: Opportunity[]) {
    const opportunity = opportunities.find(
      (opportunity) => opportunity.opportunityID === opportunityID,
    )
    if (!opportunity) return
    navigate(`${ScreenPath.opportunities}/${opportunity.opportunityID}`)
  }

  function onDeleteOpportunity(opportunityID: string) {
    openModal(
      opportunityID,
      <DeleteModal
        onClose={() => closeModal(opportunityID)}
        onConfirm={() => {
          opportunityManager.deleteOpportunity(opportunityID)
          showSnackbar('delete-opportunity', 'Opportunity deleted')
        }}
        label='opportunity'
      />,
    )
  }

  function onDeleteMultiple(opportunityIDs: string[]) {
    openModal(
      'delete-multiple-opportunities',
      <DeleteModal
        onClose={() => closeModal('delete-multiple-opportunities')}
        onConfirm={() => {
          opportunityIDs.forEach((opportunityID) => {
            opportunityManager.deleteOpportunity(opportunityID)
          })
          showSnackbar('delete-opportunity', 'Opportunities deleted')
        }}
        label='opportunities'
        messageOverride={`Are you sure you want to delete ${opportunityIDs.length} opportunities?`}
      />,
    )
  }

  function onBuildTargetListClick() {
    navigate(ScreenPath.explore)
  }

  function onUploadTargetListClick() {
    const id = 'upload-target-list'
    openModal(id, <UploadTargetListModal onClose={() => closeModal(id)} />)
  }

  async function onMergeOpportunities(opportunities: Opportunity[]) {
    try {
      // Validate that all opportunities have the same propensity target
      const basePropensityTarget = opportunities[0].propensityTarget
      for (const opportunity of opportunities) {
        if (
          opportunity.propensityTarget.productCategory.productCategoryID !==
          basePropensityTarget.productCategory.productCategoryID
        ) {
          throw new Error('Opportunities must have the same product category to be merged')
        }

        if (opportunity.propensityTarget.propensityType !== basePropensityTarget.propensityType) {
          throw new Error('Opportunities must have the same propensity type to be merged')
        }

        if (opportunity.propensityTarget.engagementType !== basePropensityTarget.engagementType) {
          throw new Error('Opportunities must have the same engagement type to be merged')
        }
      }

      // Validate that all opportunities are in draft status
      for (const opportunity of opportunities) {
        if (![OpportunityStatus.draft, OpportunityStatus.pending].includes(opportunity.status)) {
          throw new Error('Opportunities must be in draft or pending status to be merged')
        }
      }
    } catch (error: any) {
      showSnackbar('opportunity-merge-error', error.message, 'error')
      return
    }

    const id = 'merge-opportunities'
    openModal(
      id,
      <MergeOpportunitiesModal opportunities={opportunities} onClose={() => closeModal(id)} />,
    )
  }

  // -- UI
  function StatusButton({ status, count }: { status: OpportunityStatus | 'all'; count: number }) {
    return (
      <SelectableButton
        key={status}
        isSelected={statusFilter === status}
        onClick={() => onStatusFilterChange(status)}
      >
        <Stack direction='row' gap={1}>
          {status}
          <Chip label={count} size='small' />
        </Stack>
      </SelectableButton>
    )
  }

  return (
    <StatusView
      defaultMargin
      state={opportunitiesState}
      render={(opportunities) => {
        if (!opportunities) return null

        return (
          <Stack p={2} gap={2}>
            <Stack direction='row' gap={5} justifyContent='space-between'>
              <Stack direction='row' gap={1}>
                <StatusButton
                  status='all'
                  count={
                    opportunities.filter(
                      (o) => !o.AIStatus || o.AIStatus === OpportunityAIStatus.accepted,
                    ).length
                  }
                />
                <Divider orientation='vertical' flexItem />
                {Object.values(OpportunityStatus).map((status) => (
                  <StatusButton
                    status={status}
                    count={
                      getRelevantOpportunities(opportunities as Opportunity[]).filter(
                        (opportunity) => opportunity.status === status,
                      ).length
                    }
                  />
                ))}
              </Stack>
              <Stack direction='row' gap={2}>
                <AIOpportunitiesBadge>
                  <Button variant='contained' onClick={onAIOpportunitiesClick} color='inherit'>
                    <Icon icon={icons.ai} width={20} />
                  </Button>
                </AIOpportunitiesBadge>
                <DynamicMenu
                  actions={[
                    {
                      label: 'Build target list',
                      icon: <Icon icon={icons.explore} />,
                      onClick: onBuildTargetListClick,
                    },
                    {
                      label: 'Upload target list',
                      icon: <Icon icon={icons.upload} />,
                      onClick: onUploadTargetListClick,
                    },
                  ]}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <Button variant='contained' startIcon={<Icon icon={icons.plus} />}>
                    Create Opportunity
                  </Button>
                </DynamicMenu>
              </Stack>
            </Stack>
            <SearchBar
              placeholder='Search opportunities'
              value={searchText}
              onChange={(value) => setSearchText(value)}
              testId='opportunities-search-input'
            />
            <StandardCard sx={{ p: 0 }} variant='outlined'>
              <OpportunitiesTable
                opportunities={getFilteredOpportunities(opportunities as Opportunity[])}
                actions={{
                  onOpen: (opportunityID) =>
                    onViewOpportunity(opportunityID, opportunities as Opportunity[]),
                  onDelete: onDeleteOpportunity,
                  onDeleteMultiple: onDeleteMultiple,
                  additionalActions: [
                    {
                      id: 'merge',
                      label: (rows) => `Merge ${rows.length > 1 ? `(${rows.length})` : ''}`,
                      icon: <Icon icon={icons.merge} width={20} />,
                      requiresMultiple: true,
                      allowsMultiple: true,
                      onClick: (opportunityIDs) =>
                        onMergeOpportunities(
                          opportunities.filter((o) =>
                            opportunityIDs?.includes(o.opportunityID),
                          ) as Opportunity[],
                        ),
                    },
                  ],
                }}
              />
            </StandardCard>
          </Stack>
        )
      }}
    />
  )
}
