import { Box, Stack, StackProps, Typography, alpha, useTheme } from '@mui/material'

export interface TimelineProps extends StackProps {
  start: Date
  end: Date
  events: { name: string; start: Date; end?: Date }[]
}

export default function Timeline({ start, end, events, ...rest }: TimelineProps) {
  const theme = useTheme()

  const duration = end.getTime() - start.getTime()

  const BAR_HEIGHT = 20
  const BORDER_RADIUS = 1
  const BG_COLOR = alpha(theme.palette.primary.main, 0.15)

  return (
    <Stack gap={0.5} {...rest} sx={{ overflow: 'hidden', ...rest.sx }}>
      {events.map(({ name, start: eventStart, end: eventEnd }) => {
        // Constrain times to be in the timeline range
        const constrainedStart = new Date(Math.max(start.getTime(), eventStart.getTime()))
        const constrainedEnd = eventEnd
          ? new Date(Math.min(end.getTime(), eventEnd.getTime()))
          : undefined

        // Get the position and width of the event bar (relative to the timeline)
        const position = `${((constrainedStart.getTime() - start.getTime()) / duration) * 100}%`
        const width = constrainedEnd
          ? `${((constrainedEnd.getTime() - constrainedStart.getTime()) / duration) * 100}%`
          : undefined

        function Label() {
          return (
            <Typography
              className='label'
              sx={{
                fontWeight: 600,
                fontSize: 12,
                color: theme.palette.primary.main,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {name}
            </Typography>
          )
        }

        return (
          <Box
            sx={{
              width: '100%',
              minHeight: BAR_HEIGHT,
              position: 'relative',
              borderRadius: BORDER_RADIUS,
            }}
          >
            {eventEnd ? (
              <Box
                sx={{
                  position: 'absolute',
                  left: position,
                  width: width,
                  backgroundColor: BG_COLOR,
                  height: '100%',
                  borderRadius: BORDER_RADIUS,
                  justifyContent: 'flex-start',
                  display: 'flex',
                  cursor: 'pointer',
                  pt: 0.2,
                  pl: 1.5,
                  '&:hover': {
                    height: 1000,
                  },
                  '&:hover .label': {
                    whiteSpace: 'normal',
                    overflow: 'visible',
                  },
                }}
              >
                <Label />
              </Box>
            ) : (
              <Stack
                gap={0.5}
                direction='row'
                sx={{
                  position: 'absolute',
                  left: position,
                  cursor: 'pointer',
                  height: '100%',
                  mt: 0.2,
                  '&:hover': {
                    height: 1000,
                  },
                }}
              >
                <Box
                  sx={{
                    left: position,
                    width: 10,
                    height: '100%',
                    backgroundColor: BG_COLOR,
                    borderRadius: BORDER_RADIUS,
                  }}
                />
                <Label />
              </Stack>
            )}
          </Box>
        )
      })}
    </Stack>
  )
}
