import Grid from '@mui/material/Grid'
import React from 'react'

interface BarProps {
  value: number
  height?: number
  color?: string
}

const Bar: React.FC<BarProps> = ({ value, height, color }) => {
  const widthPercentage = value * 100

  return (
    <Grid container direction='column' spacing={2} justifyContent={'flex-end'}>
      <Grid
        item
        xs={12}
        style={{ zIndex: 1 }}
        container
        alignItems='center'
        justifyContent='flex-end'
      >
        <div
          style={{
            width: `${widthPercentage}%`,
            height: height ? `${height}px` : '40px',
            backgroundColor: color,
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
          }}
        />
      </Grid>
    </Grid>
  )
}

export default Bar
