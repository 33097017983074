import axios, { AxiosInstance } from 'axios'
import AdminAnnouncement from '../../model/admin/AdminAnnouncement'
import Client from '../../model/admin/Client'
import Integration, { IntegrationArgument } from '../../model/admin/Integration'
import Level, { LevelArgument } from '../../model/admin/Level'
import Method from '../../model/admin/Method'
import Metric, { MetricArgument, MetricLocation } from '../../model/admin/Metric'
import Permission from '../../model/admin/Permission'
import Role from '../../model/admin/Role'
import User, { UserArgument } from '../../model/admin/User'
import { UserAnnouncementData } from '../../model/announcement/UserAnnouncement'
import MemberType from '../../model/dashboard/MemberType'
import ProductCategory, { ProductCategoryArgument } from '../../model/explore/ProductCategory'
import ProductCategoryRating, {
  ProductCategoryRatingArgument,
  ProductCategoryRatingGoals,
} from '../../model/explore/ProductCategoryRating'
import PropensityTarget from '../../model/explore/PropensityTarget'
import Filter from '../../model/filter/Filter'
import MetricData, { MetricDataArgument } from '../../model/metric/MetricData'
import MetricsConfig from '../../model/metric/MetricsConfig'
import UserNotificationSetting from '../../model/notification/UserNotificationSetting'
import Opportunity from '../../model/opportunity/Opportunity'
import OpportunityLog, { OpportunityLogArgument } from '../../model/opportunity/OpportunityLog'
import OpportunityMethod from '../../model/opportunity/OpportunityMethod'
import OpportunityResults from '../../model/opportunity/OpportunityResults'
import DataSource from '../domain/DataSource'
import { authService, mockDataSource } from '../domain/_service.config'

export default class APIDataSource implements DataSource {
  readonly api: AxiosInstance

  constructor() {
    const baseUrl = process.env.REACT_APP_API_URL
    if (!baseUrl) throw new Error('Missing REACT_APP_API_URL from env')
    this.api = axios.create({
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    // This will intercept every API request, and then attach the auth token to the header
    // If the auth token is expired, it will auto refresh the token
    this.api.interceptors.request.use(async (config) => {
      let authToken = await authService.getAuthTokenAutoRefresh()
      if (!authToken) return config

      // Set header
      config.headers['Authorization'] = `Bearer ${authToken}`
      return config
    })
  }

  private static S3FileHelper = {
    async deleteFile(url: string): Promise<void> {
      const client = axios.create()
      await client.delete(url)
    },
    async uploadFile(url: string, file: File): Promise<void> {
      const client = axios.create()
      // Create readable stream
      await client.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
      })
    },
  }

  // Cache
  async clearCache(clientID: string): Promise<void> {
    await this.api.post(`/client/${clientID}/cache/clear`)
  }
  async generateCache(clientID: string): Promise<void> {
    await this.api.post(`/client/${clientID}/cache/generate`)
  }
  async getCacheKeys(clientID: string): Promise<string[]> {
    const res = await this.api.get<string[]>(`/client/${clientID}/cache/keys`)
    return res.data
  }

  // Global Admin
  async pushToProd(clientID: string): Promise<void> {
    await this.api.post(`/admin/push/${clientID}`)
  }

  async fetchClients(): Promise<Client[]> {
    const res = await this.api.get<Client[]>(`/admin/clients`)
    return res.data.map((client) => new Client(client))
  }
  async fetchClient(clientID: string): Promise<Client> {
    const res = await this.api.get<Client>(`/admin/clients/${clientID}`)
    return new Client(res.data)
  }
  async saveClient(client: Client): Promise<void> {
    await this.api.post(`/admin/clients/${client.clientID}`, client)
  }
  async saveClientLogo(clientID: string, logo: File): Promise<void> {
    // Get filetype
    const fileType = logo.type.split('/')[1]
    const res = await this.api.get<string>(`/admin/clients/${clientID}/logo/${fileType}`)
    await APIDataSource.S3FileHelper.uploadFile(res.data, logo)
  }

  async createClient(client: Client): Promise<void> {
    await this.api.post(`/admin/clients`, client)
  }

  async deleteClient(clientID: string): Promise<void> {
    await this.api.delete(`/admin/clients/${clientID}`)
  }

  async deleteClientLogo(clientID: string, fileType: string): Promise<void> {
    const res = await this.api.delete(`/admin/clients/${clientID}/logo/${fileType}`)
    await APIDataSource.S3FileHelper.deleteFile(res.data)
  }

  async fetchUsers(): Promise<User[]> {
    const res = await this.api.get<UserArgument[]>(`/admin/users`)
    return res.data.map((user) => new User(user))
  }
  async fetchUser(userID: string): Promise<User> {
    const res = await this.api.get<UserArgument>(`/admin/users/${userID}`)
    return new User(res.data)
  }
  async saveUser(user: User): Promise<void> {
    await this.api.post(`/admin/users/${user.userID}`, user)
  }
  async deleteUser(userID: string): Promise<void> {
    await this.api.delete(`/admin/users/${userID}`)
  }

  async fetchDefaultMethods(): Promise<Method[]> {
    const res = await this.api.get<Method[]>(`/admin/methods`)
    return res.data.map((method) => new Method(method))
  }
  async saveDefaultMethod(method: Method): Promise<void> {
    await this.api.post(`/admin/methods/${method.methodID}`, method)
  }
  async deleteDefaultMethod(methodID: string): Promise<void> {
    await this.api.delete(`/admin/methods/${methodID}`)
  }

  async fetchRoles(): Promise<Role[]> {
    const res = await this.api.get<Role[]>(`/admin/roles`)
    return res.data.map((role) => new Role(role))
  }
  async saveRole(role: Role): Promise<void> {
    await this.api.post(`/admin/roles/${role.roleID}`, role)
  }
  async deleteRole(roleID: string): Promise<void> {
    await this.api.delete(`/admin/roles/${roleID}`)
  }

  async fetchPermissions(): Promise<Permission[]> {
    const res = await this.api.get<Permission[]>(`/admin/permissions`)
    return res.data.map((permission) => new Permission(permission))
  }
  async savePermission(permission: Permission): Promise<void> {
    await this.api.post(`/admin/permissions/${permission.permissionID}`, permission)
  }
  async deletePermission(permissionID: string): Promise<void> {
    await this.api.delete(`/admin/permissions/${permissionID}`)
  }

  async fetchAdminAnnouncements(): Promise<AdminAnnouncement[]> {
    const res = await this.api.get<AdminAnnouncement[]>(`/admin/announcements`)
    return res.data
  }
  async saveAdminAnnouncement(announcement: AdminAnnouncement): Promise<void> {
    await this.api.post(`/admin/announcements/${announcement.announcementID}`, announcement)
  }
  async deleteAdminAnnouncement(announcementID: string): Promise<void> {
    await this.api.delete(`/admin/announcements/${announcementID}`)
  }

  // Client Admin
  async fetchClientUsers(clientID: string, includeSuperAdmins: boolean): Promise<User[]> {
    const res = await this.api.get<UserArgument[]>(
      `/client/${clientID}/admin/users?includeSuperAdmins=${includeSuperAdmins}`,
    )
    return res.data.map((user) => new User(user))
  }

  async fetchMethods(clientID: string): Promise<Method[]> {
    const res = await this.api.get<Method[]>(`/client/${clientID}/admin/methods`)
    return res.data.map((method) => new Method(method))
  }
  async saveMethod(clientID: string, method: Method): Promise<void> {
    await this.api.post(`/client/${clientID}/admin/methods/${method.methodID}`, method)
  }
  async deleteMethod(clientID: string, methodID: string): Promise<void> {
    await this.api.delete(`/client/${clientID}/admin/methods/${methodID}`)
  }

  async fetchLevels(clientID: string): Promise<Level[]> {
    const res = await this.api.get<LevelArgument[]>(`/client/${clientID}/admin/levels`)
    return res.data.map((level) => new Level(level))
  }

  async fetchProductCategories(clientID: string): Promise<ProductCategory[]> {
    const res = await this.api.get<ProductCategoryArgument[]>(
      `/client/${clientID}/admin/productCategories`,
    )
    return res.data.map((pc) => new ProductCategory(pc))
  }

  async fetchIntegrations(clientID: string): Promise<Integration[]> {
    const res = await this.api.get<IntegrationArgument[]>(`/client/${clientID}/admin/integrations`)
    return res.data.map((i) => new Integration(i))
  }

  // Dashboard
  async fetchDashboardMetrics(clientID: string): Promise<Metric[]> {
    const res = await this.api.get<MetricArgument[]>(`/client/${clientID}/dashboard/metrics`)
    return res.data.map((metric) => new Metric(metric))
  }

  async fetchDashboardMetricsData(
    clientID: string,
    memberType: MemberType,
    metricIDs: string[],
  ): Promise<MetricData[]> {
    const res = await this.api.post<MetricDataArgument[]>(
      `/client/${clientID}/dashboard/metrics/data`,
      {
        memberType,
        metricIDs,
      },
    )
    return res.data.map((data) => new MetricData(data))
  }

  async fetchDashboardMetricData(
    clientID: string,
    memberType: MemberType,
    metricID: string,
  ): Promise<MetricData> {
    const mockData = mockDataSource.getDashboardMetricData(clientID, memberType, metricID)
    if (mockData) return mockData

    const res = await this.api.post<MetricDataArgument>(
      `/client/${clientID}/dashboard/metrics/${metricID}/data`,
      {
        memberType,
      },
    )
    return new MetricData(res.data)
  }

  async fetchDashboardConfigs(clientID: string): Promise<MetricsConfig[]> {
    const res = await this.api.get(`/client/${clientID}/dashboard/configs`)
    return res.data.map((config: any) => new MetricsConfig(config))
  }

  async saveDashboardConfig(clientID: string, config: MetricsConfig): Promise<void> {
    const res = await this.api.post(
      `/client/${clientID}/dashboard/configs/${config.metricsConfigID}`,
      config,
    )
    return
  }

  async deleteDashboardConfig(clientID: string, configID: string): Promise<void> {
    await this.api.delete(`/client/${clientID}/dashboard/configs/${configID}`)
  }

  // Explore
  async fetchExploreConfigs(clientID: string, location: MetricLocation): Promise<MetricsConfig[]> {
    const res = await this.api.get(`/client/${clientID}/explore/configs/${location}`)
    return res.data.map((config: any) => new MetricsConfig(config))
  }
  async saveExploreConfig(clientID: string, config: MetricsConfig): Promise<void> {
    const res = await this.api.post(
      `/client/${clientID}/explore/configs/${config.metricsConfigID}`,
      config,
    )
    return
  }
  async deleteExploreConfig(clientID: string, configID: string): Promise<void> {
    await this.api.delete(`/client/${clientID}/explore/configs/${configID}`)
  }

  async fetchExploreMetrics(clientID: string): Promise<Metric[]> {
    try {
      const res = await this.api.get<MetricArgument[]>(`/client/${clientID}/explore/metrics`)
      return res.data.map((metric) => new Metric(metric))
    } catch (err) {
      return []
    }
  }

  async fetchExploreMetricData(
    clientID: string,
    metric: Metric,
    filters: Filter[] = [],
    propensityTarget?: PropensityTarget,
  ): Promise<MetricData> {
    const res = await this.api.post<MetricDataArgument>(
      `/client/${clientID}/explore/metrics/${metric.metricID}/data`,
      {
        metric,
        filters,
        propensityTarget,
      },
    )
    return new MetricData(res.data)
  }

  async fetchProductCategoryRatings(
    clientID: string,
    goalType: ProductCategoryRatingGoals,
  ): Promise<ProductCategoryRating[]> {
    const mockData = await mockDataSource.getProductCategoryRatings(clientID, goalType)
    if (mockData) return mockData

    const res = await this.api.post<ProductCategoryRatingArgument[]>(
      `/client/${clientID}/explore/productCategory/ratings`,
      {
        goalType,
      },
    )
    return res.data.map((rating) => new ProductCategoryRating(rating))
  }

  async exportMemberList(
    clientID: string,
    filters: Filter[],
    metricIDs: string[],
    propensityTarget?: PropensityTarget,
  ): Promise<string> {
    const res = await this.api.post(`/client/${clientID}/explore/members/export`, {
      filters,
      metricIDs,
      propensityTarget,
    })
    return res.data
  }

  // Opportunity
  async fetchOpportunities(clientID: string): Promise<Opportunity[]> {
    const res = await this.api.get(`/client/${clientID}/opportunities`)
    return res.data.map((opportunity: Opportunity) => new Opportunity(opportunity))
  }
  async saveOpportunity(
    clientID: string,
    opportunity: Opportunity,
    memberIDs?: string[],
  ): Promise<Opportunity> {
    const result = await this.api.post(
      `/client/${clientID}/opportunities/${opportunity.opportunityID}`,
      {
        opportunity,
        memberIDs,
      },
    )
    return new Opportunity(result.data)
  }
  async deleteOpportunity(clientID: string, opportunityID: string): Promise<void> {
    await this.api.delete(`/client/${clientID}/opportunities/${opportunityID}`)
  }

  async fetchOpportunityResults(
    clientID: string,
    opportunityID: string,
  ): Promise<OpportunityResults | null> {
    const res = await this.api.get(`/client/${clientID}/opportunities/${opportunityID}/results`)

    if (!res.data) return null

    return new OpportunityResults(res.data)
  }

  async fetchOpportunityMemberIDs(clientID: string, opportunityID: string): Promise<string[]> {
    const res = await this.api.get(`/client/${clientID}/opportunities/${opportunityID}/members`)
    return res.data
  }

  async exportOpportunityMembers(
    clientID: string,
    opportunity: Opportunity,
    metricIDs?: string[],
  ): Promise<string> {
    const res = await this.api.post(
      `/client/${clientID}/opportunities/${opportunity.opportunityID}/members/export`,
      metricIDs,
    )

    return res.data as string
  }

  async fetchOpportunityLogs(clientID: string, opportunityID: string): Promise<OpportunityLog[]> {
    const res = await this.api.get(`/client/${clientID}/opportunities/${opportunityID}/logs`)
    return res.data
  }

  async saveOpportunityLog(log: OpportunityLogArgument): Promise<OpportunityLog> {
    const res = await this.api.post(
      `/client/${log.clientID}/opportunities/${log.opportunityID}/logs`,
      log,
    )
    return res.data
  }

  async generateMarketingPlan(clientID: string, opportunity: Opportunity): Promise<string> {
    const res = await this.api.post(`client/${clientID}/gpt/plan`, {
      opportunity,
    })
    return res.data
  }

  async generateMarketingMaterial(
    clientID: string,
    opportunity: Opportunity,
    method: OpportunityMethod,
  ): Promise<string> {
    const res = await this.api.post(`client/${clientID}/gpt/method`, {
      opportunity,
      method,
    })
    return res.data
  }

  async mergeOpportunities(
    clientID: string,
    opportunities: Opportunity[],
    name: string,
  ): Promise<Opportunity> {
    const res = await this.api.post(`client/${clientID}/opportunities/merge`, {
      opportunities,
      name,
    })
    return new Opportunity(res.data)
  }

  // Metric
  async fetchMetrics(clientID: string): Promise<Metric[]> {
    const res = await this.api.get<MetricArgument[]>(`/client/${clientID}/metrics`)
    return res.data.map((metric) => new Metric(metric))
  }

  // Announcement
  async getAllUserAnnouncements(userID: string, clientID: string): Promise<UserAnnouncementData[]> {
    const res = await this.api.get<UserAnnouncementData[]>(
      `/announcements/allUserAnnouncements/${userID}?clientID=${clientID}`,
    )
    return res.data
  }

  async markAnnouncementAsRead(
    clientID: string,
    userID: string,
    announcementID: string,
  ): Promise<void> {
    await this.api.post(`/announcements/markAsRead/${clientID}/${userID}/${announcementID}`)
  }

  async markAllUserAnnouncementsAsRead(clientID: string, userID: string): Promise<void> {
    await this.api.post(`/announcements/markAllAsRead/${clientID}/${userID}`)
  }

  // Notifications
  async fetchUserNotificationSettings(
    clientID: string,
    userID: string,
  ): Promise<UserNotificationSetting[]> {
    console.log(userID)
    const res = await this.api.get<UserNotificationSetting[]>(
      `/client/${clientID}/notifications/settings/${userID}`,
    )
    return res.data.map((setting) => new UserNotificationSetting(setting))
  }

  async saveUserNotificationSetting(
    clientID: string,
    userID: string,
    setting: UserNotificationSetting,
  ): Promise<void> {
    await this.api.post(`/client/${clientID}/notifications/settings/${userID}`, setting)
  }

  async sendPushCompleteNotification(clientID: string): Promise<void> {
    await this.api.post(`/client/${clientID}/notifications/push/complete`)
  }
}
