import { useHookstate } from '@hookstate/core'
import { IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import AnnouncementCard from '../components/announcements/AnnouncementCard'
import EmptyTableView from '../components/table/EmptyTableView'
import StatusView from '../components/util/StatusView'
import { announcementManager } from '../managers/_manager.config'
import { UserAnnouncementData } from '../model/announcement/UserAnnouncement'

import { Icon } from '@iconify/react'
import NotificationSettingsView from '../components/settings/NotificationSettingsView'
import icons from '../config/icons.config'
import globalState from '../service/external/GlobalState'

type TagFilterType = 'Announcements' | 'Release Notes'

export default function AnnouncementsScreen() {
  // -- Global state
  const announcementState = useHookstate(globalState.userAnnouncementsData).get()
  const currentUserState = useHookstate(globalState.user).get()

  // -- Local state
  const [view, setView] = useState('announcements')
  const [selectedTag, setSelectedTag] = useState<TagFilterType>('Announcements')
  const [filteredAnnouncements, setFilteredAnnouncements] = useState<UserAnnouncementData[]>([])

  // -- Lifecycle
  useEffect(() => {
    if (
      !announcementState.isLoaded() &&
      !announcementState.isLoading() &&
      currentUserState.isLoaded()
    ) {
      announcementManager.fetchAnnouncements()
    } else if (announcementState.isLoaded()) {
      filterAnnouncementsByTags(selectedTag)
    }
  }, [announcementState, currentUserState])

  function filterAnnouncementsByTags(newTag: TagFilterType) {
    const allAnnouncements = announcementState.data as UserAnnouncementData[]
    if (newTag === 'Announcements') {
      setFilteredAnnouncements(allAnnouncements)
    } else {
      setFilteredAnnouncements(
        allAnnouncements.filter(
          (announcementData) =>
            announcementData.announcement.tags &&
            announcementData.announcement.tags.includes('Release Note'),
        ),
      )
    }
  }

  function handleOnFilterChange(event: React.MouseEvent<HTMLElement>, newTag: string | null) {
    if (newTag) {
      setSelectedTag(newTag as TagFilterType)
      filterAnnouncementsByTags(newTag as TagFilterType)
    }
  }

  function handleSettingsClick(event: React.MouseEvent<HTMLElement>) {
    setView('notifications')
  }

  function handleBackClick(event: React.MouseEvent<HTMLElement>) {
    setView('announcements')
  }

  const toggleButtonStyle: any = {
    padding: '0.4rem 1rem',
  }

  return (
    <Stack alignItems='center' height='100%' width='100%'>
      <Stack justifyContent='center' alignItems='center' height='100%' width='75%'>
        <Stack alignSelf='center' textAlign='center' marginTop={4} width='100%'>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            width='100%'
            position='relative'
          >
            <Typography variant='h5' alignSelf='center' style={{ marginBottom: '1rem' }}>
              Notifications Center
            </Typography>
            {view === 'announcements' ? (
              <IconButton
                onClick={handleSettingsClick}
                style={{ position: 'absolute', right: '5%' }}
              >
                <Icon icon={icons.settings} height={22} />
              </IconButton>
            ) : (
              <IconButton onClick={handleBackClick} style={{ position: 'absolute', left: 0 }}>
                <Icon icon={icons.back} height={22} />
              </IconButton>
            )}
          </Stack>

          {/* Check if settings (notifications view) is active */}
          {view === 'notifications' ? (
            <NotificationSettingsView />
          ) : (
            // Announcements view
            <StatusView
              state={announcementState}
              render={(announcements) => (
                <Stack>
                  <Stack alignSelf='center' textAlign='center'>
                    <ToggleButtonGroup
                      value={selectedTag}
                      onChange={handleOnFilterChange}
                      exclusive
                      color='primary'
                    >
                      <ToggleButton value='Announcements' style={toggleButtonStyle}>
                        All Announcements
                      </ToggleButton>
                      <ToggleButton value='Release Notes' style={toggleButtonStyle}>
                        Release Notes
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>

                  <Stack gap={2} p={4} width='100%'>
                    {filteredAnnouncements.length === 0 ? (
                      <EmptyTableView
                        title={`No ${selectedTag.toLowerCase()} found!`}
                        subtitle='Check back later for updates and announcements.'
                      />
                    ) : (
                      filteredAnnouncements.map((annoucement, index) => (
                        <AnnouncementCard
                          key={index}
                          announcementData={annoucement as UserAnnouncementData}
                        />
                      ))
                    )}
                  </Stack>
                </Stack>
              )}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
