import { Typography } from '@mui/material'
import copy from '../../config/copy.config'
import MetricAttributeResult from '../../model/opportunity/MetricAttributeResult'
import palette from '../../theme/palette'
import StringUtil from '../../util/StringUtil'
import DataTable, { DataTableColumn } from '../table/DataTable'
import StandardCard from '../util/StandardCard'

export interface AttributeResultsTableProps {
  attributes: MetricAttributeResult[]
  hideMetricCol: boolean
  showHeader?: boolean
  showPagination?: boolean
  enableColumnActions?: boolean
  onMetricNameClick?: (attribute: MetricAttributeResult) => void
}

export default function AttributeResultsTable({
  attributes,
  hideMetricCol = false,
  showHeader = true,
  showPagination = false,
  enableColumnActions = false,
  onMetricNameClick,
}: AttributeResultsTableProps) {
  function getPercentageText(percentage: number, emphasize = false) {
    function getColor() {
      if (!emphasize) return 'text.primary'
      if (percentage > 0) return palette.light.growth
      if (percentage < 0) return palette.light.churn
      return 'text.primary'
    }

    function getSign() {
      if (!emphasize) return ''
      if (percentage > 0) return '+'
      if (percentage < 0) return ''
      return ''
    }

    return (
      <Typography color={getColor()} fontWeight={emphasize ? 'bold' : ''}>
        {getSign()}
        {StringUtil.numberFormat(percentage, 2)}%
      </Typography>
    )
  }

  const cols: DataTableColumn<MetricAttributeResult>[] = [
    {
      name: copy.opportunity.view.results.attributes.table.attributeCol.title,
      sortable: true,
      valueGetter: (r) => r.attribute.toLowerCase(),
      description: copy.opportunity.view.results.attributes.table.attributeCol.description,
      render: (r) => r.attribute,
      width: 250,
    },
    {
      name: copy.opportunity.view.results.attributes.table.opensCol.title,
      sortable: true,
      valueGetter: (r) => r.openCount,
      description: copy.opportunity.view.results.attributes.table.opensCol.description,
    },
    {
      name: copy.opportunity.view.results.attributes.table.percentOpensCol.title,
      sortable: true,
      description: copy.opportunity.view.results.attributes.table.percentOpensCol.description,
      valueGetter: (r) => r.openPercent,
      render: (r) => getPercentageText(r.openPercent),
    },
    {
      name: copy.opportunity.view.results.attributes.table.targetedCol.title,
      sortable: true,
      description: copy.opportunity.view.results.attributes.table.targetedCol.description,
      valueGetter: (r) => r.targetedCount,
    },
    {
      name: copy.opportunity.view.results.attributes.table.percentTargetedCol.title,
      sortable: true,
      description: copy.opportunity.view.results.attributes.table.percentTargetedCol.description,
      valueGetter: (r) => r.targetedPercent,
      render: (r) => getPercentageText(r.targetedPercent),
    },
    {
      name: copy.opportunity.view.results.attributes.table.percentPerformanceCol.title,
      sortable: true,
      description: copy.opportunity.view.results.attributes.table.percentPerformanceCol.description,
      valueGetter: (r) => r.performance,
      render: (r) => getPercentageText(r.performance, true),
    },
  ]

  if (!hideMetricCol) {
    cols.unshift({
      name: copy.opportunity.view.results.attributes.table.metricCol.title,
      sortable: true,
      description: copy.opportunity.view.results.attributes.table.metricCol.description,
      width: 300,
      valueGetter: (r) => r.metricName.toLowerCase(),
      render: (r) =>
        onMetricNameClick ? (
          <Typography
            color='primary'
            fontWeight='bold'
            sx={{ cursor: 'pointer' }}
            onClick={() => onMetricNameClick(new MetricAttributeResult(r))}
          >
            {r.metricName}
          </Typography>
        ) : (
          r.metricName
        ),
    })
  }

  return (
    <StandardCard sx={{ p: 0 }} variant='outlined'>
      <DataTable
        data={attributes}
        columns={cols}
        rowKey={(record) => record.getUniqueID()}
        showPagination={showPagination}
        disableColumnActions={!enableColumnActions}
        showHeader={showHeader}
        initialSortColumn={cols.find((c) => c.name === '% Performance')}
        initialSortDirection='desc'
      />
    </StandardCard>
  )
}
