import { useHookstate } from '@hookstate/core'
import { Stack, StackProps } from '@mui/material'
import TimeframeChartData from '../../model/chart/TimeframeChartData'
import globalState from '../../service/external/GlobalState'
import StatisticNumber from './StatisticNumber'
import StatisticTitle from './StatisticTitle'

export interface TimeframeChartDataProps extends StackProps {
  data: TimeframeChartData
  description?: string
}

const toggleStyles = {
  buttonGroup: {
    flexDirection: 'row',
    my: 1,
  },
  button: {
    fontSize: '0.8rem',
    padding: '0.125rem 0.3125rem',
    margin: '0 0.0625rem',
  },
}

export default function TimeframeStatistic({
  data,
  description,
  ...props
}: TimeframeChartDataProps) {
  const timeframe = useHookstate(globalState.dashboardTimeframe).get() // TODO: Don't tie to dashboard

  const value = data.values.find((valueData) => valueData.timeline === timeframe)?.value

  return (
    <Stack {...props}>
      <StatisticTitle title={data.title} description={description} />
      <StatisticNumber {...data} value={value ?? '-'} valueSuffix={`(${timeframe})`} />
    </Stack>
  )
}
