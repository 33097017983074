import { Icon } from '@iconify/react'
import { Dialog, Divider, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import icons from '../../config/icons.config'
import ChartType from '../../model/chart/ChartType'
import FullLevelsChartData, {
  FullLevelData,
  MovementType,
} from '../../model/chart/FullLevelsChartData'
import StandardChartData from '../../model/chart/StandardChartData'
import StatisticData from '../../model/chart/StatisticData'
import { Timeframe } from '../../model/chart/Timeframe'
import palette from '../../theme/palette'
import StringUtil from '../../util/StringUtil'
import EChart from '../chart/Echart'
import Statistic from '../chart/Statistic'
import ModalCloseButton from '../modal/ModalCloseButton'
import ModalContent from '../modal/ModalContent'
import StandardCard from '../util/StandardCard'
import TimeframePicker from '../util/TimelinePicker'

export interface LevelMovementModalProps {
  levelData: FullLevelData
  initialTimeframe: Timeframe
  onClose: () => void
}

export function LevelMovementModal({
  levelData,
  initialTimeframe,
  onClose,
}: LevelMovementModalProps) {
  // -- Local state
  const [timeframe, setTimeframe] = useState(initialTimeframe)
  const [selectedMovementType, setSelectedMovementType] = useState(MovementType.upIn)

  // -- Logic
  const movementData = FullLevelsChartData.getMovementData(levelData, timeframe)
  if (!movementData) return null
  const selectedMovementData = movementData[selectedMovementType]

  // -- UI
  return (
    <Dialog open onClose={onClose} maxWidth='xl' fullWidth>
      <ModalCloseButton onClose={onClose} />
      <ModalContent>
        <Stack gap={2} sx={{ px: 2 }}>
          <Stack
            direction='row'
            sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-end' }}
          >
            <Stack direction='row' alignItems='baseline' gap={1}>
              <Typography variant='h4'>Level {levelData.name}</Typography>
              <Typography variant='body2'>
                ({levelData.scoreStart}-{levelData.scoreEnd} MPP)
              </Typography>
            </Stack>
            <Stack
              sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              <TimeframePicker onChange={setTimeframe} value={timeframe} />
            </Stack>
            <Stack direction='row' alignItems='baseline' gap={1}>
              <Typography variant='h5'>
                {StringUtil.numberFormat(levelData.memberCount, undefined, false)}
              </Typography>
              <Typography variant='body2'>Members</Typography>
            </Stack>
          </Stack>

          <Stack direction='row' gap={2} sx={{ width: '100%' }}>
            <MovementStack title={'Movement Into Level'}>
              <MovementStat
                title='Members Moved Up'
                value={movementData[MovementType.upIn].count}
                icon={icons.growth}
                color={palette.light.growth}
                selected={selectedMovementType === MovementType.upIn}
                onSelect={() => setSelectedMovementType(MovementType.upIn)}
              />
              <MovementStat
                title='Members Moved Down'
                value={movementData[MovementType.downIn].count}
                icon={icons.churn}
                color={palette.light.churn}
                selected={selectedMovementType === MovementType.downIn}
                onSelect={() => setSelectedMovementType(MovementType.downIn)}
              />
              <MovementStat
                title='New Members'
                value={movementData[MovementType.new].count}
                icon={icons.plus}
                color={palette.light.growth}
                selected={selectedMovementType === MovementType.new}
                onSelect={() => setSelectedMovementType(MovementType.new)}
              />
            </MovementStack>
            <MovementStack title={'Movement Out of Level'}>
              <MovementStat
                title='Members Moved Up'
                value={movementData[MovementType.upOut].count}
                icon={icons.growth}
                color={palette.light.growth}
                selected={selectedMovementType === MovementType.upOut}
                onSelect={() => setSelectedMovementType(MovementType.upOut)}
              />
              <MovementStat
                title='Members Moved Down'
                value={movementData[MovementType.downOut].count}
                icon={icons.churn}
                color={palette.light.churn}
                selected={selectedMovementType === MovementType.downOut}
                onSelect={() => setSelectedMovementType(MovementType.downOut)}
              />
              <MovementStat
                title='Lost Members'
                value={movementData.lost.count}
                icon={icons.minus}
                color={palette.light.churn}
                selected={selectedMovementType === MovementType.lost}
                onSelect={() => setSelectedMovementType(MovementType.lost)}
              />
            </MovementStack>
          </Stack>
          <Divider sx={{ width: '100%' }} />
          {selectedMovementData.count > 0 ? (
            <Stack direction='row' gap={5} sx={{ width: '100%', minHeight: 300 }}>
              <Stack>
                <Statistic
                  data={
                    new StatisticData({
                      title: 'Avg # of Products',
                      value: selectedMovementData.avgProductCount,
                      size: 'lg',
                    })
                  }
                />
                <Statistic
                  data={
                    new StatisticData({
                      title: 'Avg # of Transactions',
                      value: selectedMovementData.avgTransactionCount,
                      size: 'lg',
                    })
                  }
                />
                <Statistic
                  data={
                    new StatisticData({
                      title: 'Total Assets',
                      value: selectedMovementData.totalAssets,
                      size: 'lg',
                      valuePrefix: '$',
                    })
                  }
                />
              </Stack>

              <Stack sx={{ flex: 1, width: 300 }} direction='row' gap={1}>
                <Stack sx={{ height: '100%', flex: 1 }} gap={1}>
                  <Typography variant='h6'>Products Added</Typography>
                  <EChart
                    type={ChartType.bar}
                    data={
                      new StandardChartData({
                        points: selectedMovementData.productsAdded.map((product) => ({
                          key: product.productCategoryID,
                          value: product.count,
                        })),
                      })
                    }
                    valueAxis={{ name: 'Members' }}
                  />
                </Stack>
                <Stack sx={{ height: '100%', flex: 1 }} gap={1}>
                  <Typography variant='h6'>Products Lost</Typography>
                  <EChart
                    type={ChartType.bar}
                    data={
                      new StandardChartData({
                        points: selectedMovementData.productsLost.map((product) => ({
                          key: product.productCategoryID,
                          value: product.count,
                        })),
                      })
                    }
                    valueAxis={{ name: 'Members' }}
                  />
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Typography variant='body2' sx={{ alignSelf: 'center', py: 15 }}>
              No members moved in this category
            </Typography>
          )}
        </Stack>
      </ModalContent>
    </Dialog>
  )
}

function MovementStack({ title, children }: { title: string; children: JSX.Element[] }) {
  return (
    <StandardCard variant='outlined' sx={{ flex: 1 }}>
      <Stack sx={{ alignItems: 'center' }} gap={1}>
        <Typography variant='overline'>{title}</Typography>
        <Stack direction='row' gap={1}>
          {children}
        </Stack>
      </Stack>
    </StandardCard>
  )
}

function MovementStat({
  title,
  value,
  icon,
  color,
  selected,
  onSelect,
}: {
  title: string
  value: number
  icon: string
  color: string
  selected: boolean
  onSelect: () => void
}) {
  return (
    <Stack
      alignItems='center'
      gap={1}
      sx={{
        minWidth: 200,
        p: 1,
        flex: 1,
        borderRadius: 1,
        background: selected ? palette.light.grey[300] : null,
        cursor: 'pointer',
        '&:hover': {
          background: palette.light.grey[200],
        },
      }}
      onClick={onSelect}
    >
      <Stack
        sx={{
          borderRadius: '50%',
          backgroundColor: color,
          width: 40,
          height: 40,
          color: 'white',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon icon={icon} width={20} />
      </Stack>
      <Statistic data={new StatisticData({ title, value })} center />
    </Stack>
  )
}
