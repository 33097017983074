import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { Badge, IconButton, Stack, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import icons from '../../config/icons.config'
import { useModal } from '../../contexts/ModalContext'
import { announcementManager } from '../../managers/_manager.config'
import User from '../../model/admin/User'
import { UserAnnouncementData } from '../../model/announcement/UserAnnouncement'
import { NavigationPage, ScreenPath, getHomeScreenPath } from '../../navigation'
import globalState from '../../service/external/GlobalState'
import PopUpAnnouncementModal from '../announcements/PopUpAnnouncementModal'
import Logo from '../util/Logo'
import PermissionView from '../util/PermissionView'
import SelectableButton from '../util/SelectableButton'
import StatusView from '../util/StatusView'
import UserMenu from './UserMenu'

export const HEADER_HEIGHT = '70px'

export interface HeaderProps {
  pages: NavigationPage[]
}

const POP_UP_ANNOUNCEMENT_MODAL_ID = 'new-unread-announcement'

export default function Header({ pages }: HeaderProps) {
  // -- Theme
  const theme = useTheme()

  // Navigation
  const navigate = useNavigate()
  const location = useLocation()

  // Global state
  const userState = useHookstate(globalState.user).get()
  const announcementState = useHookstate(globalState.userAnnouncementsData).get()
  const [announcementDotContent, setAnnouncementDotContent] = useState(0)
  const [isAnnouncementModalOpen, setIsAnnouncementModalOpen] = useState(false)

  const { openModal, closeModal } = useModal()

  // -- Actions
  function onLogoClick() {
    if (!userState.data) throw new Error('No user set')
    navigate(getHomeScreenPath(userState.data as User))
  }

  function onAnnouncementClick() {
    navigate(ScreenPath.announcements)
  }

  function handleAnnouncementModalClosed(announcementId: string | undefined) {
    if (userState.data && announcementId) {
      announcementManager.markAnnouncementAsRead(announcementId).then(() => {
        closeModal(POP_UP_ANNOUNCEMENT_MODAL_ID)
      })
    }
  }

  function updateAnnouncementDot() {
    if (announcementState.isLoaded() && announcementState.data) {
      const unreadAnnouncements = announcementState.data.filter((a) => !a.isRead)
      setAnnouncementDotContent(unreadAnnouncements.length)
      if (unreadAnnouncements.length > 0) {
        if (!isAnnouncementModalOpen) {
          let selectedAnnouncement: UserAnnouncementData | undefined = undefined
          for (const announcement of unreadAnnouncements) {
            if (announcement.announcement.isPopup) {
              selectedAnnouncement = announcement as UserAnnouncementData
              break
            }
          }
          if (selectedAnnouncement) {
            openModal(
              POP_UP_ANNOUNCEMENT_MODAL_ID,
              <PopUpAnnouncementModal
                announcementData={selectedAnnouncement}
                onClose={() =>
                  handleAnnouncementModalClosed(selectedAnnouncement?.announcement.announcementID)
                }
              />,
            )
            setIsAnnouncementModalOpen(true)
          }
        }
      }
    }
  }

  useEffect(() => {
    updateAnnouncementDot()
  }, [announcementState])

  // -- UI
  function PageButton({ page, isSelected }: { page: NavigationPage; isSelected: boolean }) {
    function onPageButtonClick() {
      page.action?.()
      navigate(page.path)
    }

    return (
      <SelectableButton
        isSelected={isSelected}
        onClick={onPageButtonClick}
        startIcon={page.icon}
        selectedColor='primary'
      >
        {page.label}
      </SelectableButton>
    )
  }

  return (
    <Stack
      direction='row'
      gap={5}
      alignItems='center'
      px={2}
      py={1}
      position='fixed'
      zIndex={10}
      width='100%'
      height={HEADER_HEIGHT}
      sx={{ background: 'white', borderBottom: 1, borderBottomColor: theme.palette.grey[200] }}
    >
      <Logo onClick={onLogoClick} />
      <Stack direction='row' gap={1} sx={{ flexGrow: 1 }}>
        {pages.map((page) => (
          <PermissionView key={page.path} requiredPermissions={page.requiredPermissions}>
            <PageButton
              key={page.path}
              page={page}
              isSelected={location.pathname.includes(page.path)}
            />
          </PermissionView>
        ))}
      </Stack>
      <Stack direction='row' gap={3.5} alignItems={'center'}>
        <Badge
          variant='dot'
          badgeContent={announcementDotContent}
          overlap='circular'
          color={'error'}
        >
          <IconButton onClick={onAnnouncementClick}>
            <Icon icon={icons.notification} height={18} />
          </IconButton>
        </Badge>
        <StatusView state={userState} render={(user) => <UserMenu user={user as User} />} />
      </Stack>
    </Stack>
  )
}
