import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Ref, forwardRef, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import CustomChipController from '../components/modal/CustomChipController'
import CustomTextFieldController from '../components/modal/CustomTextFieldController'
import PhoneInput from '../components/modal/PhoneInput'
import icons from '../config/icons.config'
import { useModal } from '../contexts/ModalContext'
import { adminManager, authManager } from '../managers/_manager.config'
import User, { UserArgument } from '../model/admin/User'
import globalState from '../service/external/GlobalState'

interface PasswordTextFieldProps {
  label: string
  required?: boolean
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  helperText?: React.ReactNode | string
  showPasswordStrength?: boolean
}
interface FormValues {
  firstName: string
  lastName: string
  displayName: string
  phone: string
  email: string
  accountID: string
}

export default function ProfileScreen() {
  // Global state
  const userState = useHookstate(globalState.user).get()
  const user = userState.data
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(true)

  function ProfileSection() {
    const formRef = useRef<HTMLFormElement>(null)

    function handleSaveClick() {
      if (formRef.current) {
        formRef.current.requestSubmit()
      }
    }

    function Header() {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            margin: '10px 20px 0px 20px',
          }}
        >
          <Typography variant='h5'>Profile</Typography>
          <Button variant='contained' onClick={handleSaveClick} disabled={!isSaved || isSaving}>
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </div>
      )
    }

    return (
      <>
        <Header />
        <ProfileForm ref={formRef} user={user as User} />
      </>
    )
  }

  const ProfileForm = forwardRef((props: { user: User }, ref: Ref<HTMLFormElement>) => {
    const { user } = props
    const { control, handleSubmit, getValues, setValue, watch } = useForm<FormValues>({
      defaultValues: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone, // Set phone as default value
      },
    })
    const { showSnackbar } = useModal()

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
      const userArgument: UserArgument = {
        ...data,
        userID: user.userID,
        clientIDs: user.clientIDs,
        username: user.username,
        roleIDs: user.roleIDs,
      }

      const updatedUser = new User(userArgument)
      try {
        await adminManager.saveUser(updatedUser)
        showSnackbar('user-save-success', 'User saved successfully', 'success')
      } catch (error) {
        console.error('Error saving user:', error)
        showSnackbar('user-save-error', 'Error saving user. Please try again.', 'error')
      }
    }

    return (
      <form ref={ref} onSubmit={handleSubmit(onSubmit)} style={{ margin: '20px' }}>
        <Stack direction='row' gap={2}>
          <Stack gap={2} flex={1}>
            <CustomTextFieldController
              name='firstName'
              control={control}
              label='First Name'
              defaultValue={user.firstName}
              rules={{ required: 'This field is required' }}
            />

            {/* Use Controller for PhoneInput */}
            <Controller
              name='phone'
              control={control}
              render={({ field }) => (
                <PhoneInput
                  label='Phone'
                  value={field.value}
                  onChange={(phone) => field.onChange(phone)} // Ensure onChange updates form state
                  fullWidth
                />
              )}
            />

            <CustomChipController
              name='roles'
              control={control}
              label='Roles'
              options={[]}
              defaultValue={user.roleIDs}
              disabled
            />
          </Stack>
          <Stack gap={2} flex={1}>
            <CustomTextFieldController
              name='lastName'
              control={control}
              label='Last Name'
              defaultValue={user.lastName}
              rules={{ required: 'This field is required' }}
            />
            <CustomTextFieldController
              name='email'
              control={control}
              label='Email'
              disabled
              defaultValue={user.email}
              rules={{ required: 'This field is required' }}
            />
          </Stack>
        </Stack>
        <button type='submit' style={{ display: 'none' }}></button>
      </form>
    )
  })

  const getPasswordStrengthIcon = (strength: number) => {
    if (strength < 40) return icons.faceSad
    if (strength < 80) return icons.faceNeutral
    return icons.faceHappy
  }

  const PasswordTextField: React.FC<PasswordTextFieldProps> = ({
    label,
    required = false,
    value,
    onChange,
    helperText,
    showPasswordStrength = false,
  }) => {
    const [showPassword, setShowPassword] = useState(false)
    const getPasswordStrength = (password: string) => {
      let strength = 0
      if (passwordRequirements.length.test(password)) strength += 20
      if (passwordRequirements.uppercase.test(password)) strength += 20
      if (passwordRequirements.lowercase.test(password)) strength += 20
      if (passwordRequirements.number.test(password)) strength += 20
      if (passwordRequirements.specialCharacter.test(password)) strength += 20
      return strength
    }

    return (
      <TextField
        type={showPassword ? 'text' : 'password'}
        label={label}
        required={required}
        value={value}
        onChange={onChange}
        fullWidth
        helperText={helperText}
        style={{ marginBottom: '10px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {showPasswordStrength && (
                <Icon
                  icon={getPasswordStrengthIcon(getPasswordStrength(value))}
                  fontSize={20}
                  color={
                    getPasswordStrength(value) < 40
                      ? 'red'
                      : getPasswordStrength(value) < 80
                      ? 'orange'
                      : 'green'
                  }
                />
              )}
              <IconButton onClick={() => setShowPassword((prev) => !prev)} edge='end' size='small'>
                {showPassword ? <Icon icon='carbon:view-off' /> : <Icon icon='carbon:view' />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    )
  }
  const passwordRequirements = {
    length: /^(?=.{8,})/,
    uppercase: /^(?=.*[A-Z])/,
    lowercase: /^(?=.*[a-z])/,
    number: /^(?=.*\d)/,
    specialCharacter: /^(?=.*[!@#$%^&*])/,
  }
  const requirementsList = [
    'At least 8 characters long',
    'At least 1 uppercase letter',
    'At least 1 lowercase letter',
    'At least 1 number',
    'At least 1 special character',
  ]

  function ChangePasswordForm() {
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const { showSnackbar } = useModal()

    const validatePassword = () =>
      Object.values(passwordRequirements).every((regex) => regex.test(newPassword))

    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault()

      if (!validatePassword()) {
        showSnackbar('password-update-error', 'Password must meet requirements', 'error')
        return
      } else if (newPassword !== confirmPassword) {
        showSnackbar('passwords-dont-match', 'Passwords do not match', 'error')
        return
      }

      try {
        await authManager.changePassword(currentPassword, newPassword)
        showSnackbar('password-update-success', 'Password updated successfully', 'success')
      } catch (error) {
        console.error('Error updating password:', error)
        showSnackbar('password-update-error', 'Error updating password. Please try again.', 'error')
      }
    }
    const passwordsMatch = newPassword === confirmPassword

    return (
      <form onSubmit={handleSubmit} style={{ margin: '20px' }}>
        <Stack direction='column' gap={2}>
          <Typography variant='h6'>Change Password</Typography>
          <Stack direction='row' gap={2}>
            <Stack direction='column' gap={1} flex={1}>
              <PasswordTextField
                label='Current Password'
                required
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <PasswordTextField
                label='New Password'
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                showPasswordStrength
              />
              <PasswordTextField
                label='Confirm New Password'
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {errorMessage && (
                <Typography color='error' style={{ whiteSpace: 'pre-line' }}>
                  {errorMessage}
                </Typography>
              )}
            </Stack>
            <Stack direction='column' gap={2} flex={1}>
              <Card
                style={{
                  padding: '20px',
                  marginRight: '10px',
                  height: '100%',
                  boxShadow: 'none',
                }}
                variant='outlined'
              >
                <Typography variant='body2' style={{ fontWeight: 'bold' }} mb={1}>
                  Password Requirements:
                </Typography>
                <Box component='ul' style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                  {requirementsList.map((requirement, index) => (
                    <Typography key={index} component='li' variant='body2' color='text.secondary'>
                      {requirement}
                    </Typography>
                  ))}
                </Box>
              </Card>
            </Stack>
          </Stack>
          <Button
            variant='contained'
            type='submit'
            sx={{ width: 80 }}
            disabled={!passwordsMatch || newPassword === '' || currentPassword === ''}
          >
            Submit
          </Button>
        </Stack>
      </form>
    )
  }

  // function MFASection() {
  //   // -- Local state
  //   const [enabledMethods, setEnabledMethods] = useState<string[]>([])
  //   const [setupMethod, setSetupMethod] = useState<MFAMethod | null>(MFAMethod.app)

  //   // -- Lifecycle
  //   useEffect(() => {
  //     fetch()
  //   }, [])

  //   // Functions
  //   async function fetch() {
  //     setEnabledMethods(await authManager.getEnabledMFAMethods())
  //   }

  //   // -- Actions
  //   function handleSetupClick(method: MFAMethod) {
  //     setSetupMethod(method)
  //   }

  //   function handleCancelClick() {
  //     setSetupMethod(null)
  //   }

  //   // -- UI
  //   function AuthAppSetupView() {
  //     return (
  //       <Stack gap={1} alignItems='flex-start'>
  //         <Typography variant='body2' color='text.secondary'>
  //           Authenticator apps and browser extensions like Duo Mobile, Microsoft Authenticator,
  //           etc... generate one-time passwords that are used as a second factor to verify your
  //           identity when prompted during sign-in
  //         </Typography>
  //         <Typography variant='subtitle2'>Scan the QR code</Typography>
  //         <QRCode value='https://www.example.com' />
  //         <Typography variant='body2' color='text.secondary'>
  //           Scan the QR code below with your authenticator app to add your account
  //         </Typography>
  //         <Typography variant='subtitle2'>Verify the code from the app</Typography>
  //         <TextField placeholder='XXXXXX' />
  //         <Button variant='contained'>Verify</Button>
  //       </Stack>
  //     )
  //   }

  //   const options = [
  //     {
  //       value: MFAMethod.sms,
  //       icon: <Icon icon={icons.sms} width={25} />,
  //       label: 'SMS/Text Message',
  //       description:
  //         'Get one-time codes sent to your phone via SMS to complete authentication requests',
  //       enabled: enabledMethods.includes(MFAMethod.sms),
  //     },
  //     {
  //       value: MFAMethod.app,
  //       icon: <Icon icon={icons.phone} width={25} />,
  //       label: 'Authenticator App',
  //       description:
  //         'Use an authentication app or browser extension to get two-factor authentication codes when prompted',
  //       enabled: enabledMethods.includes('authenticator'),
  //       onSetup: () => setSetupMethod(MFAMethod.app),
  //       setupView: <AuthAppSetupView />,
  //     },
  //   ]

  //   return (
  //     <Stack p={3}>
  //       <Typography variant='h6' mb={1}>
  //         Multi-Factor Authentication
  //       </Typography>
  //       <Typography variant='body2' mb={2}>
  //         Two-factor authentication adds an additional layer of security to your account by
  //         requiring more than just a password to sign in
  //       </Typography>
  //       <Stack gap={1}>
  //         <Card sx={{ boxShadow: 'none' }} variant='outlined'>
  //           <CardContent>
  //             <Typography variant='subtitle1'>Preferred Method:</Typography>
  //             <Typography variant='body2' color='text.secondary' mb={1}>
  //               Set your preferred method to use for two-factor authentication when signing in
  //             </Typography>
  //             <Select value={'sms'} fullWidth>
  //               {options
  //                 .filter((option) => option.enabled)
  //                 .map((option) => (
  //                   <MenuItem value={option.value}>{option.label}</MenuItem>
  //                 ))}
  //             </Select>
  //           </CardContent>
  //         </Card>
  //         <Card sx={{ p: 2, boxShadow: 'none' }} variant='outlined'>
  //           <CardContent>
  //             <Stack gap={2}>
  //               <Typography variant='subtitle1'>MFA Options</Typography>
  //               <Divider />
  //               {options.map((option) => (
  //                 <Stack gap={2}>
  //                   <Stack direction='row' gap={2} alignItems='center'>
  //                     {option.icon}
  //                     <Stack flex={1}>
  //                       <Typography variant='subtitle2'>{option.label}</Typography>
  //                       <Typography variant='body2' color='text.secondary' mb={1}>
  //                         {option.description}
  //                       </Typography>
  //                     </Stack>
  //                     {!option.enabled &&
  //                       (setupMethod === option.value ? (
  //                         <Button onClick={handleCancelClick}>Cancel</Button>
  //                       ) : (
  //                         <Button onClick={() => handleSetupClick(option.value)}>Setup</Button>
  //                       ))}
  //                   </Stack>
  //                   {setupMethod === option.value && option.setupView}
  //                 </Stack>
  //               ))}
  //             </Stack>
  //           </CardContent>
  //         </Card>
  //       </Stack>
  //     </Stack>
  //   )
  // }

  // Placeholder state variables - To be filled out later

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
      <Card sx={{ width: '60%', maxWidth: '80%', padding: 2 }}>
        <ProfileSection />
        <Divider />
        <ChangePasswordForm />
        {/* <Divider /> */}
        {/* <MFASection /> */}
      </Card>
    </Box>
  )
}
