import { Stack } from '@mui/material'
import InfoTag from '../metric/InfoTag'
import { DataTableColumn } from './DataTable'
import { TableSortLabel } from './TableSortLabel'

export interface DataTableHeaderProps<T> {
  column: DataTableColumn<T>
  sortDirection: 'asc' | 'desc' | undefined
  onSort: () => void
}

export default function DataTableHeader<T>({
  column,
  sortDirection,
  onSort,
}: DataTableHeaderProps<T>) {
  const baseHeader = (
    <Stack direction='row' alignItems='center' gap={0.5}>
      <div
        style={{
          lineHeight: '1.2em',
          whiteSpace: 'normal',
          maxHeight: '2.4em',
          overflow: 'hidden',
          fontWeight: 'bold',
        }}
      >
        {column.name}
      </div>
      {column.description && <InfoTag description={column.description} size={16} />}
    </Stack>
  )

  if (!column.sortable) return baseHeader

  return (
    <TableSortLabel direction={sortDirection} onClick={onSort}>
      {baseHeader}
    </TableSortLabel>
  )
}
