import { useHookstate } from '@hookstate/core'
import { LoadingButton } from '@mui/lab'
import { Button, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import copy from '../../config/copy.config'
import { useModal } from '../../contexts/ModalContext'
import { exploreManager, opportunityManager } from '../../managers/_manager.config'
import ExploreOpportunityStatus from '../../model/explore/ExploreOpportunityStatus'
import ProductCategory from '../../model/explore/ProductCategory'
import PropensityTarget, { EngagementType } from '../../model/explore/PropensityTarget'
import Filter, { FilterFactory } from '../../model/filter/Filter'
import Opportunity from '../../model/opportunity/Opportunity'
import PropensityType from '../../model/propensity/PropensityType'
import { ScreenPath } from '../../navigation'
import globalState from '../../service/external/GlobalState'
import CreateOpportunityModal from '../opportunity/CreateOpportunityModal'
import BackButton from '../util/BackButton'
import ConfirmModal from '../util/ConfirmModal'
import DynamicMenu from '../util/DynamicMenu'
import ExportMembersButton from '../util/ExportMembersButton'
import PropensityTypeSelector from './PropensityTypeSelector'

export interface TargetProductCategoryBarProps {
  productCategory: ProductCategory
}

export default function TargetProductCategoryBar({
  productCategory,
}: TargetProductCategoryBarProps) {
  // Navigation
  const navigate = useNavigate()
  const { openModal, closeModal, showSnackbar } = useModal()

  // Global state
  const propensityType = useHookstate(globalState.explorePropensityType).get()
  const engagementType = useHookstate(globalState.exploreEngagementType).get()
  const opportunity = useHookstate(globalState.exploreOpportunity).get()
  const opportunityStatus = useHookstate(globalState.exploreOpportunityStatus).get()
  const filters = useHookstate(globalState.exploreFilters).get()

  // -- Local state
  const [opportunityIsSaving, setOpportunityIsSaving] = useState(false)

  // -- Actions
  function onBackButtonClick() {
    if (opportunity && opportunityStatus === ExploreOpportunityStatus.editing) {
      const id = 'back'
      openModal(
        id,
        <ConfirmModal
          title='Exit'
          message={`You're currently editing an opportunity. If you exit, your changes will be lost. Are you sure you want to exit?`}
          confirmation='Exit'
          color='warning'
          onConfirm={() => {
            exploreManager.resetState()
            navigate(-1)
          }}
          onClose={() => closeModal(id)}
        />,
      )
    } else {
      exploreManager.resetState()
      navigate(-1)
    }
  }

  function onPropensityTypeChange(type: PropensityType) {
    if (!type) return
    exploreManager.setPropensityType(type)
  }

  function onEngagementTypeChange(type: EngagementType) {
    if (!type) return
    exploreManager.setEngagementType(type)
  }

  function onCreateOpportunityClick(split: boolean = false) {
    if (!productCategory) return
    const id = 'create-opportunity'
    openModal(
      id,
      <CreateOpportunityModal
        propensityTarget={
          new PropensityTarget({
            productCategory,
            propensityType,
            engagementType,
          })
        }
        filters={filters as Filter[]}
        split={split}
        onCreate={async (name, metricData) => {
          if (split) {
            if (!metricData) throw new Error('Metric data is required for split opportunities')
            await exploreManager.createSplitOpportunities(name, metricData)
            navigate(ScreenPath.opportunities)
          } else {
            const opportunity = await exploreManager.createOpportunity(name)
            navigate(ScreenPath.opportunities)
            navigate(`${ScreenPath.opportunities}/${opportunity.opportunityID}`)
          }
        }}
        onClose={() => closeModal(id)}
      />,
    )
  }

  async function onSaveOpportunityClick() {
    if (!opportunity) return
    const id = opportunity.opportunityID
    // TODO: Filter.create is a band-aid fix - need to figure out how to handle hookstate immutable transformations
    let updatedOpportunity = opportunity.update({
      filters: filters.map((f) => FilterFactory.create(f as Filter)),
      propensityTarget: {
        ...opportunity.propensityTarget,
        propensityType,
        engagementType,
      },
    })

    let message = 'The following opportunity filters have been updated: '
    let updatedFilters = updatedOpportunity.filters.map((filter: any) => filter.name)

    if (updatedFilters.length > 0) {
      message += updatedFilters.join(', ')
    } else {
      message = 'All opportunity filters have been removed.'
    }

    try {
      setOpportunityIsSaving(true)
      updatedOpportunity = (await opportunityManager.saveOpportunity(
        updatedOpportunity,
      )) as Opportunity

      await opportunityManager.saveOpportunityLog(id, message)
      setOpportunityIsSaving(false)
    } catch (error) {
      setOpportunityIsSaving(false)
      showSnackbar(
        'opportunity-save-error',
        'Failed to save opportunity. Please try again',
        'error',
      )
      return
    }

    navigate(ScreenPath.opportunities)
    navigate(`${ScreenPath.opportunities}/${id}`)
  }

  // -- UI
  if (!productCategory) return null
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
      <Stack direction='row' gap={1} alignItems='center'>
        <BackButton onClick={onBackButtonClick} />
        <Typography fontSize={'2rem'} fontWeight='bold'>
          {productCategory.name}
        </Typography>
      </Stack>
      <Stack direction='row' gap={1}>
        <Stack alignSelf='center'>
          <ExportMembersButton
            exportFunction={(metrics) =>
              exploreManager.exportMemberList(
                metrics,
                new PropensityTarget({
                  productCategory,
                  propensityType,
                  engagementType,
                }),
              )
            }
            propensityRequired
          />
        </Stack>
        <ToggleButtonGroup
          size='small'
          value={engagementType}
          onChange={(_, value) => onEngagementTypeChange(value)}
          exclusive
        >
          <ToggleButton value={EngagementType.all} key={EngagementType.all} sx={{ paddingY: 0 }}>
            All members
          </ToggleButton>
          <ToggleButton
            value={EngagementType.focussed}
            key={EngagementType.focussed}
            sx={{ paddingY: 0 }}
          >
            <Tooltip
              title={`Only members whose top ${productCategory.type} product is ${productCategory.name}`}
            >
              <div>Focused</div>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
        <PropensityTypeSelector
          value={propensityType}
          onChange={onPropensityTypeChange}
          size='small'
        />
        {opportunity ? (
          <LoadingButton
            variant='contained'
            onClick={onSaveOpportunityClick}
            loading={opportunityIsSaving}
            disabled={opportunityStatus === ExploreOpportunityStatus.viewing}
          >
            Save Opportunity
          </LoadingButton>
        ) : (
          <DynamicMenu
            sx={{ height: '100%' }}
            actions={[
              {
                label: copy.explore.opportunity.create.title,
                fontWeight: 'medium',
                description: copy.explore.opportunity.create.description,
                onClick: onCreateOpportunityClick,
                testID: 'explore-create-opportunity-dropdown-button',
              },
              {
                label: copy.explore.opportunity.split.title,
                fontWeight: 'medium',
                description: copy.explore.opportunity.split.description,
                onClick: () => onCreateOpportunityClick(true),
              },
            ]}
          >
            <Button
              variant='contained'
              data-testid='explore-member-create-opportunity-btn'
              disabled={opportunityStatus === ExploreOpportunityStatus.viewing}
              sx={{ height: '100%' }}
            >
              Create Opportunity
            </Button>
          </DynamicMenu>
        )}
      </Stack>
    </Stack>
  )
}
