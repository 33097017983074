import { Icon } from '@iconify/react'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import copy from '../../config/copy.config'
import icons from '../../config/icons.config'
import Opportunity from '../../model/opportunity/Opportunity'
import DateUtil from '../../util/DateUtil'
import StringUtil from '../../util/StringUtil'
import FilterChips from '../explore/filter/FilterChips'
import DataTable, { DataTableColumn } from '../table/DataTable'
import { RowActions } from '../table/RowOptionsMenu'
import PropensityTypeIndicator from './PropensityTypeIndicator'
import StatusChip from './StatusChip'

export interface OpportunitiesTableProps {
  opportunities: Opportunity[]
  actions: RowActions
  onMerge?: (items: Opportunity[]) => void
}

export const opportunityCols: {
  [key: string]: DataTableColumn<Opportunity>
} = {
  name: {
    name: 'Name',
    sortable: true,
    width: 300,
    valueGetter: (o) => o.name.toLowerCase(),
    render: (o) => (
      <Stack direction='row' gap={1} sx={{ width: 300, alignItems: 'center' }}>
        {o.AIStatus ? <Icon icon={icons.ai} /> : null}
        <Typography noWrap sx={{ overflow: 'ellipsis' }}>
          {o.name}
        </Typography>
      </Stack>
    ),
  },
  filters: {
    name: 'Filters',
    width: 300,
    valueGetter: (o) => o.filters,
    render: (o) => {
      return (
        <Stack
          sx={{
            width: 300,
            height: '100%',
          }}
        >
          <FilterChips filters={o.filters} />
        </Stack>
      )
    },
  },
  status: {
    name: 'Status',
    sortable: true,
    width: 100,
    valueGetter: (o) => o.status.toLowerCase(),
    render: (o) => <StatusChip status={o.status} />,
  },
  propensityType: {
    name: 'Type',
    sortable: true,
    description: 'Type of propensity: Growth or Churn',
    width: 100,
    valueGetter: (o) => o.propensityTarget.propensityType,
    render: (o) => <PropensityTypeIndicator propensityType={o.propensityTarget.propensityType} />,
  },
  productCategory: {
    name: 'Product Category',
    sortable: true,
    width: 160,
    valueGetter: (o) => o.propensityTarget.productCategory.name.toLowerCase(),
    render: (o) => o.propensityTarget.productCategory.name,
  },
  targetedMemberCount: {
    name: 'Targeted Members',
    sortable: true,
    description: copy.opportunity.table.headers.targetedMembers,
    valueGetter: (o) => o.targetedMemberCount,
    render: (o) => StringUtil.numberFormat(o.targetedMemberCount),
  },
  impactedMembers: {
    name: 'Impacted Members',
    description: copy.opportunity.table.headers.impactedMembers,
    sortable: true,
    valueGetter: (o) => o.impactedMembers,
    render: (o) => StringUtil.numberFormat(o.impactedMembers),
  },
  estimatedValue: {
    name: 'Estimated Value',
    description: copy.opportunity.table.headers.predictedGrowth,
    sortable: true,
    valueGetter: (o) => o.predictedGrowthLower,
    render: (o) => {
      const predictedValuesStringRange = StringUtil.dollarRangeFormat(
        o.predictedGrowthLower,
        o.predictedGrowthUpper,
      )
      return predictedValuesStringRange
    },
  },
  totalAssetValue: {
    name: 'Asset Value',
    sortable: true,
    description: copy.opportunity.table.headers.totalAssetValue,
    valueGetter: (o) => o.totalAssetValue,
    render: (o) => (o.totalAssetValue ? StringUtil.dollarFormat(o.totalAssetValue) : '-'),
  },
  averagePropensity: {
    name: 'Average Propensity',
    sortable: true,
    width: 20,
    valueGetter: (o) => o.averagePropensity,
    render: (o) => StringUtil.numberFormat(o.averagePropensity),
  },
  dates: {
    name: 'Timeline',
    sortable: true,
    description: copy.opportunity.table.headers.timeline,
    width: 140,
    valueGetter: (o) => o.startDate,
    render: (o) => (
      <div style={{ width: 120 }}>
        <span>{DateUtil.getDateString(o.startDate)} - </span>
        <br />
        <span>{DateUtil.getDateString(o.endDate)}</span>
      </div>
    ),
  },
  aiScore: {
    name: 'AI Score',
    description: copy.opportunity.table.headers.aiScore,
    sortable: true,
    valueGetter: (o) => o.AIScore,
    render: (o) => StringUtil.numberFormat(o.AIScore),
  },
}

function OpportunitiesTable({ opportunities, actions }: OpportunitiesTableProps) {
  return (
    <DataTable
      data={opportunities}
      columns={[
        opportunityCols.name,
        opportunityCols.status,
        opportunityCols.propensityType,
        opportunityCols.productCategory,
        opportunityCols.targetedMemberCount,
        opportunityCols.impactedMembers,
        opportunityCols.estimatedValue,
        opportunityCols.totalAssetValue,
        opportunityCols.dates,
      ]}
      rowKey={(opportunity: Opportunity) => opportunity.opportunityID}
      actions={actions}
      headerVariant='light'
      showCheckbox
      data-testid='opportunities-table'
      useQueryParams={true}
    />
  )
}
export default React.memo(OpportunitiesTable)
