import Metric from '../admin/Metric'
import { DensityData, PhysicalLocation } from '../chart/DensityMapData'
import GroupedChartData, {
  GroupedChartDataArgument,
  GroupedDataPoint,
} from '../chart/GroupedChartData'
import MetricData from '../metric/MetricData'
import MetricAttributeResult, { MetricAttributeResultArgument } from './MetricAttributeResult'

export enum PerformanceMetric {
  opens = '% of Opens',
  targeted = '% of Targeted',
  performance = 'Performance',
}

export interface LocationPerformanceData {
  openData: DensityData[]
  targetedData: DensityData[]
  performanceData: DensityData[]
  physicalLocations: PhysicalLocation[]
}
export interface OpportunityResultsArgument {
  opportunityID: string
  growthStats?: {
    conversionRate: number
    adoptedProductCount: number
    adoptedMemberCount: number
    totalBalanceAdded: number
    averageBalanceAdded: number
    productValueAdded: number
    applicationsCount: number
    newMoneyAdded: number
    pctDiffThreeMonth: number
    pctDiffLastYear: number
    pctDiffNonTargeted: number
  }
  churnStats?: {
    churnRate: number
    churnedProductCount: number
    churnedMemberCount: number
    totalBalanceLost: number
    averageBalanceLost: number
    productValueLost: number
  }
  historyData: GroupedChartDataArgument
  attributeResults: MetricAttributeResultArgument[]
  locationPerformance: LocationPerformanceData
  AISummary?: string
}

export default class OpportunityResults {
  readonly opportunityID: string
  readonly growthStats?: {
    readonly conversionRate: number
    readonly adoptedProductCount: number
    readonly adoptedMemberCount: number
    readonly totalBalanceAdded: number
    readonly averageBalanceAdded: number
    readonly productValueAdded: number
    readonly applicationsCount: number
    readonly newMoneyAdded: number
    readonly pctDiffThreeMonth: number
    readonly pctDiffLastYear: number
    readonly pctDiffNonTargeted: number
  }
  readonly churnStats?: {
    readonly churnRate: number
    readonly churnedProductCount: number
    readonly churnedMemberCount: number
    readonly totalBalanceLost: number
    readonly averageBalanceLost: number
    readonly productValueLost: number
  }

  readonly historyData: GroupedChartData
  readonly attributeResults: MetricAttributeResult[]
  readonly locationPerformance: LocationPerformanceData
  readonly AISummary?: string

  constructor(arg: OpportunityResultsArgument) {
    this.opportunityID = arg.opportunityID
    this.growthStats = arg.growthStats
    this.churnStats = arg.churnStats
    this.historyData = new GroupedChartData(arg.historyData)
    this.attributeResults = arg.attributeResults.map((r) => new MetricAttributeResult(r))
    this.locationPerformance = arg.locationPerformance
    this.AISummary = arg.AISummary
  }

  getMetricData(metric: Metric) {
    // Get points from attribute results
    const attributeResults = this.getAttributeResultsForMetric(metric.metricID)
    const points: {
      opens: GroupedDataPoint
      targeted: GroupedDataPoint
      performance: GroupedDataPoint
    }[] = []
    for (const result of attributeResults) {
      // Group the points by it's key, to make sure they don't get separated during sorting
      points.push({
        opens: {
          key: result.attribute,
          value: result.openPercent,
          group: '% of Opens',
        },
        targeted: {
          key: result.attribute,
          value: result.targetedPercent,
          group: '% of Targeted',
        },
        performance: {
          key: result.attribute,
          value: result.performance,
          group: 'Performance',
        },
      })
    }

    // Sort points
    // 1. Sort by options, if available
    // 2. Sort by metric.sortBy (key or value) if available
    // 3. Sort by value
    const options = metric.options?.map((option) => option.label) || []
    points.sort((a, b) => {
      const aIndex = options.indexOf(a.targeted.key)
      const bIndex = options.indexOf(b.targeted.key)
      if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex

      if (aIndex !== -1) return -1
      if (bIndex !== -1) return 1
      if (metric.sortBy === 'key') return a.targeted.key.localeCompare(b.targeted.key)

      // Sort by open value other wise
      return b.opens.value - a.opens.value
    })

    // Expand points
    const expandedPoints = points.flatMap((point) => [point.opens, point.targeted])

    // Return MetricData
    return new MetricData({
      metricID: metric.metricID,
      chartData: new GroupedChartData({ points: expandedPoints }),
    })
  }

  hasAttributesForMetric(metricID: string) {
    return this.getAttributeResultsForMetric(metricID).length > 0
  }

  getBestAttributeResults(limit: number) {
    return this.attributeResults.sort((a, b) => b.performance - a.performance).slice(0, limit)
  }
  getWorstAttributeResults(limit: number) {
    return this.attributeResults.sort((a, b) => a.performance - b.performance).slice(0, limit)
  }

  getAttributeResultsForMetric(metricID: string) {
    return this.attributeResults.filter((result) => result.metricID === metricID)
  }
  getBestAttributeResultsForMetric(metricID: string, limit: number) {
    return this.getAttributeResultsForMetric(metricID)
      .sort((a, b) => b.performance - a.performance)
      .slice(0, limit)
  }
  getWorstAttributeResultsForMetric(metricID: string, limit: number) {
    return this.getAttributeResultsForMetric(metricID)
      .sort((a, b) => a.performance - b.performance)
      .slice(0, limit)
  }
}
