import { useHookstate } from '@hookstate/core'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
// import logo from '../../assets/logo-full.svg'
import { Icon } from '@iconify/react'
import { Outlet } from 'react-router-dom'
import icons from '../../config/icons.config'
import { adminManager, announcementManager } from '../../managers/_manager.config'
import Client from '../../model/admin/Client'
import Role from '../../model/admin/Role'
import User from '../../model/admin/User'
import globalState from '../../service/external/GlobalState'
import StatusView from './StatusView'

export default function ClientRequiredView() {
  // Global state
  const clientsState = useHookstate(globalState.clients).get()
  const userState = useHookstate(globalState.user).get()
  const rolesState = useHookstate(globalState.roles).get()
  const currentClient = useHookstate(globalState.currentClient).get()

  // -- Local state
  const [selectedClientId, setSelectedClientId] = useState(currentClient?.clientID)

  // -- Lifecycle
  useEffect(() => {
    if (!clientsState.isLoaded()) adminManager.fetchClients()
    if (!rolesState.isLoaded()) adminManager.fetchRoles()
  }, [])

  useEffect(() => {
    if (currentClient && userState.isLoaded()) {
      announcementManager.fetchAnnouncements()
    }
  }, [currentClient, userState])

  // -- Actions
  function handleSelectPressed() {
    if (!selectedClientId) return
    adminManager.selectClient(selectedClientId)
  }

  // -- UI
  if (currentClient) return <Outlet />

  function getClientOptions(
    clients: Client[],
    user: User,
    roles: Role[],
  ): { clientID: string; logo?: string; label: string }[] {
    const availableClients = user.getAvailableClients(clients, roles)
    const sortedClients = availableClients.sort((a, b) => a.name.localeCompare(b.name))
    return sortedClients.map((client) => ({
      clientID: client.clientID,
      logo: client.logoURL,
      label: client.name,
    }))
  }

  return (
    <Box display='flex' alignItems='center' justifyContent='center' mt={20}>
      <Card>
        <CardContent>
          <Stack gap={1} p={2} width={400} alignItems='center'>
            <Icon icon={icons.bank} width={50} />
            <Stack textAlign='center'>
              <Typography variant='h6'>Select an Organization</Typography>
              <Typography color='text.secondary' mb={2}>
                Select an organization to continue
              </Typography>
            </Stack>
            <StatusView
              defaultMargin
              state={clientsState}
              render={(clients) => (
                <StatusView
                  state={userState}
                  render={(user) => (
                    <StatusView
                      state={rolesState}
                      render={(roles) => (
                        <Autocomplete
                          fullWidth
                          options={getClientOptions(
                            clients as Client[],
                            user as User,
                            roles as Role[],
                          )}
                          value={getClientOptions(
                            clients as Client[],
                            user as User,
                            roles as Role[],
                          ).find((option) => option.clientID === selectedClientId)}
                          onChange={(_, option) => option && setSelectedClientId(option.clientID)}
                          disableClearable
                          renderInput={(params) => <TextField {...params} />}
                          renderOption={(props, option) => (
                            <Box component='li' {...props}>
                              <Stack direction='row' alignItems='center' gap={1}>
                                <img loading='lazy' width={70} src={option.logo} />
                                <Divider orientation='vertical' flexItem />
                                {option.label}
                              </Stack>
                            </Box>
                          )}
                        />
                      )}
                    />
                  )}
                />
              )}
            />
            <Button
              variant='contained'
              disabled={!selectedClientId}
              onClick={handleSelectPressed}
              fullWidth
              sx={{ mt: 3 }}
            >
              Select
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  )
}
