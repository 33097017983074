export enum NotificationMethod {
  email = 'email',
}

export enum NotificationName {
  newDataLoads = 'New data loads',
  newAIOpportunities = 'New AI opportunities',
  // reports = 'Weekly or monthly reports',
  opportunityComments = 'Opportunity Comments',
  activeOpportunities = 'Opportunity starts',
  evaluatedOpportunities = 'Opportunity begins evaluating period',
  completedOpportunities = 'Opportunity completes evaluating period',
  // opportunityReports = 'Opportunity reports',
}

export interface UserNotificationSettingArgument {
  userID: string
  clientID: string
  name: NotificationName
  methods: NotificationMethod[]
}

export default class UserNotificationSetting {
  readonly userID: string
  readonly clientID: string
  readonly name: NotificationName
  readonly methods: NotificationMethod[]

  constructor(arg: UserNotificationSettingArgument) {
    this.userID = arg.userID
    this.clientID = arg.clientID
    this.name = arg.name
    this.methods = arg.methods
  }

  setMethod(method: NotificationMethod, value: boolean) {
    if (value) {
      if (!this.methods.includes(method)) {
        this.methods.push(method)
      }
    } else {
      const index = this.methods.indexOf(method)
      if (index !== -1) {
        this.methods.splice(index, 1)
      }
    }
  }
}
