import { authService, dataSource, trackingService } from '../service/domain/_service.config'
import globalState from '../service/external/GlobalState'
import AdminManager from './AdminManager'
import AnnouncementManager from './AnnouncementManager'
import AuthManager from './AuthManager'
import DashboardManager from './DashboardManager'
import ExploreManager from './ExploreManager'
import MetricManager from './MetricManager'
import NotificationManager from './NotificationManager'
import OpportunityManager from './OpportunityManager'
import TrackingManager from './TrackingManager'

export const authManager = new AuthManager(authService, dataSource, globalState, trackingService)

export const adminManager = new AdminManager(dataSource, globalState)
export const dashboardManager = new DashboardManager(dataSource, globalState)
export const exploreManager = new ExploreManager(dataSource, globalState)
export const opportunityManager = new OpportunityManager(dataSource, globalState)
export const metricManager = new MetricManager(dataSource, globalState)
export const announcementManager = new AnnouncementManager(dataSource, globalState)
export const trackingManager = new TrackingManager(trackingService, authService, globalState)
export const notificationManager = new NotificationManager(dataSource, globalState)
