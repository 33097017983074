import Metric from '../model/admin/Metric'
import { EngagementType } from '../model/explore/PropensityTarget'
import Opportunity from '../model/opportunity/Opportunity'
import OpportunityMethod from '../model/opportunity/OpportunityMethod'
import PropensityType from '../model/propensity/PropensityType'
import DataSource from '../service/domain/DataSource'
import StateService from '../service/domain/StateService'
import { deleteOneInList, load, saveOneInList } from '../util/LoadableHookstateHelpers'

export default class OpportunityManager {
  readonly dataSource: DataSource
  readonly state: StateService

  constructor(dataSource: DataSource, state: StateService) {
    this.dataSource = dataSource
    this.state = state
  }

  async fetchOpportunities() {
    if (this.state.opportunities.get().isLoaded()) return

    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')

    const fetchFunction = () => this.dataSource.fetchOpportunities(client.clientID)
    await load(this.state.opportunities, fetchFunction)
  }
  async saveOpportunity(
    opportunity: Opportunity,
    memberIDs?: string[],
    useSaveResult: boolean = true,
  ) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')
    const saveFunction = (opportunity: Opportunity) =>
      this.dataSource.saveOpportunity(client.clientID, opportunity, memberIDs)
    const matchFunction = (item: Opportunity) => item.opportunityID === opportunity.opportunityID
    return await saveOneInList(
      this.state.opportunities,
      opportunity,
      saveFunction,
      matchFunction,
      useSaveResult,
    )
  }
  async deleteOpportunity(opportunityID: string) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')
    const matchFunction = (item: Opportunity) => item.opportunityID === opportunityID
    const deleteFunction = () => this.dataSource.deleteOpportunity(client.clientID, opportunityID)
    await deleteOneInList(this.state.opportunities, matchFunction, deleteFunction)
  }

  // Merge
  async mergeOpportunities(opportunities: Opportunity[], name: string) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')

    const saveFunction = async () =>
      await this.dataSource.mergeOpportunities(client.clientID, opportunities, name)
    const matchFunction = (item: Opportunity) =>
      opportunities.some((o) => o.opportunityID === item.opportunityID)

    return await saveOneInList(
      this.state.opportunities,
      {} as Opportunity,
      saveFunction,
      matchFunction,
      true,
    )
  }

  // Results
  fetchOpportunityResults(opportunityID: string) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')
    const fetchFunction = () =>
      this.dataSource.fetchOpportunityResults(client.clientID, opportunityID)
    return load(this.state.currentOpportunityResults, fetchFunction)
  }

  // AI Opportunities
  async acceptAIOpportunity(opportunity: Opportunity) {
    await this.saveOpportunity(opportunity.acceptAI())
  }
  async rejectAIOpportunity(opportunity: Opportunity) {
    await this.saveOpportunity(opportunity.rejectAI(), undefined, false)
  }

  // Generative AI
  async generateMarketingPlan(opportunity: Opportunity) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')

    return this.dataSource.generateMarketingPlan(client.clientID, opportunity)
  }
  async generateMarketingMethodContent(opportunity: Opportunity, method: OpportunityMethod) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')

    return this.dataSource.generateMarketingMaterial(client.clientID, opportunity, method)
  }

  // Export
  async exportMembers(opportunity: Opportunity, exportMetrics: Metric[]) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')
    const metricIDs = exportMetrics.map((metric) => metric.metricID)
    const result = await this.dataSource.exportOpportunityMembers(
      client.clientID,
      opportunity,
      metricIDs,
    )

    return result
  }

  // Import

  /*
    Read the target list CSV. This file should contain a list of member IDs.
    If the file is valid, return the list of member IDs.
    If the file is invalid, throw an error.
  */
  async readTargetList(file: File) {
    return new Promise<string[]>((resolve, reject) => {
      try {
        // Read the file
        const reader = new FileReader()
        reader.readAsText(file)

        // Parse the file
        reader.onload = (event) => {
          if (!event.target) return reject(new Error('Could not read file'))
          const content = event.target.result
          // Make sure the content is a string
          if (typeof content !== 'string') return reject(new Error('Invalid file content'))

          const memberIDs = content
            .split('\n')
            .map((line) => line.trim())
            .filter((line) => line !== '')

          // Make sure there are member IDs
          if (memberIDs.length === 0) return reject(new Error('No member IDs found in file'))

          // Make sure the member IDs are valid (no commas - this could come from extra columns in the CSV)
          if (memberIDs.some((id) => id.includes(',')))
            return reject(new Error('Invalid member ID found in file (contains comma)'))

          // Make sure the member IDs are unique
          const uniqueMemberIDs = [...new Set(memberIDs)]
          if (uniqueMemberIDs.length !== memberIDs.length)
            return reject(new Error('Duplicate member IDs found in file'))

          resolve(memberIDs)
        }
      } catch (error: any) {
        reject(new Error('Could not read file'))
      }
    })
  }

  async createManualOpportunity(
    name: string,
    productCategoryID: string,
    propensityType: PropensityType,
    memberIDs: string[],
  ) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')
    const productCategory = this.state.productCategories
      .get()
      .data?.find((item) => item.productCategoryID === productCategoryID)
    if (!productCategory)
      throw new Error(`Could not find product category with ID ${productCategoryID}`)

    // Create the opportunity
    const opportunity = Opportunity.create(
      name,
      productCategory,
      propensityType,
      EngagementType.all,
      undefined,
      true,
    )

    // Save the opportunity
    await this.saveOpportunity(opportunity, memberIDs)

    return opportunity
  }

  async fetchOpportunityLogs(opportunityID: string) {
    if (this.state.currentOpportunityLogs.get().isLoaded()) return []

    const client = this.state.currentClient.get()
    if (!client) throw new Error('No client selected')

    return await this.dataSource.fetchOpportunityLogs(client.clientID, opportunityID)
  }

  async saveOpportunityLog(opportunityID: string, content: string, isSystemLog: boolean = false) {
    const client = this.state.currentClient.get()
    const user = this.state.user.get()

    if (!client) throw new Error('No client selected')
    if (!user) throw new Error('No user selected')

    return await this.dataSource.saveOpportunityLog({
      clientID: client.clientID,
      opportunityID,
      content,
      isSystemLog,
      userID: !isSystemLog && user.data ? user.data.userID : undefined,
      userDisplayName: user.data ? user.data.getFullName() : undefined,
    })
  }
}
