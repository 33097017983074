import { useHookstate } from '@hookstate/core'
import { useEffect } from 'react'
import { useModal } from '../../../contexts/ModalContext'
import { adminManager } from '../../../managers/_manager.config'
import AdminAnnouncement from '../../../model/admin/AdminAnnouncement'
import globalState from '../../../service/external/GlobalState'
import ConfirmModal from '../../util/ConfirmModal'
import StatusView from '../../util/StatusView'
import AdminTabView, { AdminTab } from '../AdminTabView'
import AdminAnnouncementsModal from '../modals/AdminAnnouncementModal'
import DeleteModal from '../modals/DeleteModal'

export default function AnnouncementsTab() {
  const { openModal, closeModal, showSnackbar } = useModal()

  const adminAnnouncementsState = useHookstate(globalState.adminAnnouncements).get()
  const adminAnnouncements = adminAnnouncementsState.data

  useEffect(() => {
    if (!adminAnnouncementsState.isLoaded() && !adminAnnouncementsState.isLoading()) {
      adminManager.fetchAdminAnnouncements()
    }
  }, [adminAnnouncementsState])

  const handleOnCreateSubmit = async (
    updatedAdminAnnouncement: AdminAnnouncement,
    recipientCount: number,
    closeModalID: string,
  ) => {
    closeModal(closeModalID)
    const id = 'confirm-admin-announcement'
    openModal(
      id,
      <ConfirmModal
        title='Send announcement?'
        message={`Are you sure that you want to send this announcement to the ${recipientCount} selected recipients?`}
        confirmation='Send Announcement'
        onConfirm={() => {
          adminManager.saveAdminAnnouncement(updatedAdminAnnouncement)
          showSnackbar('announcement-sent', `Announcement sent to ${recipientCount} recipients`)
        }}
        onClose={() => closeModal(id)}
        color='warning'
      />,
    )
  }

  const handleCreate = () => {
    const adminAnnouncement = AdminAnnouncement.create()
    openModal(
      'create-admin-announcement',
      <AdminAnnouncementsModal
        adminAnnouncement={adminAnnouncement}
        adminAnnouncements={adminAnnouncements as AdminAnnouncement[]}
        isCreating
        onClose={() => closeModal('create-admin-announcement')}
        onSubmit={(newAnnouncement, recipientCount) =>
          handleOnCreateSubmit(newAnnouncement, recipientCount, 'create-admin-announcement')
        }
      />,
    )
  }

  const handleEdit = (adminAnnouncementID: string) => {
    const announcementData = adminAnnouncements?.find(
      (announcement) => announcement.announcementID === adminAnnouncementID,
    ) as AdminAnnouncement | undefined
    if (!announcementData) return
    const adminAnnouncement = new AdminAnnouncement(announcementData)
    openModal(
      adminAnnouncement.announcementID,
      <AdminAnnouncementsModal
        adminAnnouncements={adminAnnouncements as AdminAnnouncement[]}
        adminAnnouncement={adminAnnouncement as AdminAnnouncement}
        onSubmit={(updatedAnnouncement, recipientCount) =>
          handleOnCreateSubmit(updatedAnnouncement, recipientCount, 'update-admin-announcement')
        }
        onClose={() => closeModal(adminAnnouncement.announcementID)}
      />,
    )
  }

  const handleDuplicate = (adminAnnouncementID: string) => {
    const announcementData = adminAnnouncements?.find(
      (announcement) => announcement.announcementID === adminAnnouncementID,
    ) as AdminAnnouncement | undefined
    if (!announcementData) return
    const adminAnnouncement = new AdminAnnouncement(announcementData)
    openModal(
      adminAnnouncementID,
      <AdminAnnouncementsModal
        adminAnnouncements={adminAnnouncements as AdminAnnouncement[]}
        isCreating
        adminAnnouncement={adminAnnouncement.duplicate()}
        onSubmit={(newAnnouncement, recipientCount) =>
          handleOnCreateSubmit(newAnnouncement, recipientCount, adminAnnouncementID)
        }
        onClose={() => closeModal(adminAnnouncement.announcementID)}
      />,
    )
  }

  const handleDelete = (adminAnnouncementID: string) => {
    openModal(
      adminAnnouncementID,
      <DeleteModal
        onClose={() => closeModal(adminAnnouncementID)}
        onConfirm={() => {
          adminManager.deleteAdminAnnouncement(adminAnnouncementID)
          showSnackbar('announcement-deleted', 'Announcement deleted')
        }}
        label='announcement'
      />,
    )
  }

  const tab: AdminTab<AdminAnnouncement> = {
    label: 'Announcement',
    pluralLabel: 'Announcements',
    columns: [
      { name: 'Title', valueGetter: (a) => a.title },
      { name: 'Description', valueGetter: (a) => a.description },
      // { name: 'Content', valueGetter: (a) => a.content },
      { name: 'Show as Popup', valueGetter: (a) => (a.isPopup ? 'Yes' : 'No') },
      { name: 'Tags', valueGetter: (a) => a.tags.join(', ') },
    ],
    searchFilter: (searchText, record) =>
      record.title.toLowerCase().includes(searchText.toLowerCase()),
    rowKey: (record) => record.announcementID,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDuplicate: handleDuplicate,
    onDelete: handleDelete,
  }

  return (
    <StatusView
      state={adminAnnouncementsState}
      render={(announcements) => (
        <AdminTabView data={announcements as AdminAnnouncement[]} tab={tab} />
      )}
    />
  )
}
