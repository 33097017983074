export const styles = {
  rowFlex: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'stretch',
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
  },
  columnFlex: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centeredFlex: { justifyContent: 'center', alignItems: 'center' },
  cellStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderBottom: '0.0625rem solid #e0e0e0',
    padding: '0.125rem 0.25rem',
  },
  levelName: { fontSize: '2.2rem', marginRight: '0.625rem', fontWeight: 700 },
  circle: (size: number, color: string) => {
    const bubbleSize = size
    const iconSize = bubbleSize * 0.4

    return {
      bubble: {
        width: `${size}px`,
        marginRight: bubbleSize * 0.25,
        height: `${size}px`,
        ...styles.centeredFlex,
        backgroundColor: color,
        borderRadius: '50%',
        boxShadow: '0.3rem 0.25rem 0.375rem rgba(0, 0, 0, 0.1)',
        transition: 'ease-out 0.3s',
      },
      icon: {
        fontSize: `${iconSize}px`,
        color: 'white',
      },
    }
  },
}

export const toggleStyles = {
  buttonGroup: {
    flexDirection: 'row',
    my: 1,
  },
  button: {
    fontSize: '0.8rem',
    padding: '0.125rem 0.3125rem',
    margin: '0 0.0625rem',
  },
}

export const FIXED_HEIGHT = { height: '60px' }
