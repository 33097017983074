import {
  BaseTextFieldProps,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'
import { useEffect, useState } from 'react'
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone'
import 'react-international-phone/style.css'

const phoneUtil = PhoneNumberUtil.getInstance() // Initialize the phone number utility

// Phone validation function
const isPhoneValid = (phone: string, countryIso: string) => {
  try {
    const parsedPhone = phoneUtil.parseAndKeepRawInput(phone, countryIso.toUpperCase())
    return phoneUtil.isValidNumber(parsedPhone)
  } catch (error) {
    return false
  }
}

export interface PhoneInputProps extends BaseTextFieldProps {
  value: string
  onChange: (phone: string) => void
}

export default function PhoneInput({ value, onChange, ...restProps }: PhoneInputProps) {
  const [formattedPhone, setFormattedPhone] = useState('')
  const [isValid, setIsValid] = useState(true) // State to track if the phone number is valid

  const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'us',
    value,
    countries: defaultCountries,
    onChange: (data) => {
      try {
        const parsedPhone = phoneUtil.parse(data.phone, country.iso2.toUpperCase())
        const rawPhone = phoneUtil.format(parsedPhone, PhoneNumberFormat.E164)
        onChange(rawPhone) // Store raw phone number

        const formatted = phoneUtil.format(parsedPhone, PhoneNumberFormat.INTERNATIONAL)
        setFormattedPhone(formatted) // Display formatted phone number

        // Validate the phone number
        const valid = isPhoneValid(data.phone, country.iso2)
        setIsValid(valid)
      } catch (error) {
        setFormattedPhone(data.phone)
        setIsValid(false)
        onChange(data.phone)
      }
    },
  })

  // Ensure the formatted phone number is displayed correctly
  useEffect(() => {
    try {
      const parsedPhone = phoneUtil.parse(phone, country.iso2.toUpperCase())
      const formatted = phoneUtil.format(parsedPhone, PhoneNumberFormat.INTERNATIONAL)
      setFormattedPhone(formatted)
    } catch (error) {
      setFormattedPhone(phone)
    }
  }, [country.dialCode, phone])

  return (
    <div>
      <TextField
        variant='outlined'
        label='Phone number'
        color='primary'
        placeholder='Phone number'
        value={formattedPhone} // Display formatted phone number
        onChange={(e) => {
          if (e.target.value.startsWith('+' + country.dialCode)) {
            handlePhoneValueChange(e)
          }
        }}
        type='tel'
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start' style={{ marginRight: '2px', marginLeft: '-8px' }}>
              <Select
                MenuProps={{
                  style: {
                    height: '300px',
                    width: '360px',
                    top: '10px',
                    left: '-34px',
                  },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                }}
                sx={{
                  width: 'max-content',

                fieldset: {
                  display: 'none',
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: 'block',
                  },
                },
                '&.Mui-focused': {
                  fieldset: {
                    display: 'none',
                  },
                },
                '.MuiSelect-select': {
                  padding: '8px',
                  paddingRight: '24px !important',
                  },
                  svg: {
                    right: 0,
                  },
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value as CountryIso2)}
                renderValue={(value) => (
                  <FlagImage
                    iso2={value}
                    style={{ display: 'flex', width: '24px', height: '24px' }}
                  />
                )}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c)
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagImage
                        iso2={country.iso2}
                        style={{ marginRight: '8px', width: '24px', height: '24px' }}
                      />
                      <Typography marginLeft={1} marginRight='8px'>
                        {country.name}
                      </Typography>
                      <Typography color='gray'>+{country.dialCode}</Typography>
                    </MenuItem>
                  )
                })}
              </Select>
            </InputAdornment>
          ),
        }}
        {...restProps}
      />

      {/* Display validation message */}
      {!isValid && <Typography color='error'>Phone number is not valid</Typography>}
    </div>
  )
}
