import { Icon } from '@iconify/react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import icons from '../../config/icons.config'
import { useModal } from '../../contexts/ModalContext'
import Metric from '../../model/admin/Metric'
import SelectMetricModal from './SelectMetricModal'

export interface MetricPickerProps {
  availableMetrics: Metric[]
  selectedMetricName?: string
  onMetricClick: (metric: Metric) => void
  error?: string
}

export default function MetricPicker({
  availableMetrics,
  selectedMetricName,
  onMetricClick,
  error,
}: MetricPickerProps) {
  const { openModal, closeModal } = useModal()

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()

    const id = 'split-by-metric'
    openModal(
      id,
      <SelectMetricModal
        availableMetrics={availableMetrics}
        onMetricClick={(metric) => {
          onMetricClick(metric)
          closeModal(id)
        }}
        onClose={() => closeModal(id)}
      />,
    )
  }

  function SelectInput() {
    return (
      <Select
        label='Split By'
        value={selectedMetricName}
        onClick={handleClick}
        endAdornment={<Icon icon={icons.search} width={20} />}
        sx={{
          '& .MuiSelect-icon': {
            display: 'none', // Hides the chevron icon
          },
        }}
        MenuProps={{ disableAutoFocusItem: true }} // Prevents the popup from opening
        open={false} // Keeps the dropdown permanently closed
      >
        {availableMetrics.map((metric) => (
          <MenuItem key={metric.name} value={metric.name}>
            {metric.name}
          </MenuItem>
        ))}
      </Select>
    )
  }

  return (
    <FormControl fullWidth variant='outlined' error={!!error}>
      <InputLabel>Split By</InputLabel>
      <SelectInput />
    </FormControl>
  )
}
