import { Stack, StackProps, Typography } from '@mui/material'
import Filter from '../../../model/filter/Filter'
import FilterChip from './FilterChip'

export interface FilterChipsProps extends StackProps {
  filters: Filter[]
  appliedFilters?: string[]
  filterChanged?: boolean
  center?: boolean
  onInvert?: (metricID: string) => void
  onDelete?: (metricID: string) => void
}

export default function FilterChips({
  filters,
  appliedFilters = [],
  filterChanged = false,
  center = false,
  onInvert,
  onDelete,
  ...rest
}: FilterChipsProps) {
  return (
    <Stack
      direction='row'
      gap={1}
      flexWrap='wrap'
      alignItems='center'
      justifyContent={center ? 'center' : 'flex-start'}
      width='100%'
      height='100%'
      {...rest}
    >
      {filters.length > 0 ? (
        filters.map((filter) => (
          <FilterChip
            key={filter.metricID}
            filter={filter}
            isActive={appliedFilters.includes(filter.metricID)}
            onInvert={onInvert}
            onDelete={onDelete}
          />
        ))
      ) : (
        <Typography fontWeight='medium' fontSize='0.9rem'>
          No filters
        </Typography>
      )}
    </Stack>
  )
}
