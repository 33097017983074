import ChartData from './ChartData'
import ChartDataType from './ChartDataType'
import StandardChartData from './StandardChartData'
import { Timeframe } from './Timeframe'

export enum MovementType {
  upIn = 'up-in',
  upOut = 'up-out',
  new = 'new',
  downIn = 'down-in',
  downOut = 'down-out',
  lost = 'lost',
}
export interface LevelMovement {
  timeframe: Timeframe
  movementType: MovementType
  count: number
  avgProductCount: number
  avgTransactionCount: number
  totalAssets: number
  productsAdded: ProductCategoryCount[]
  productsLost: ProductCategoryCount[]
}

export interface ProductCategoryCount {
  productCategoryID: string
  count: number
}

export interface LevelMemberCount {
  level: string
  count: number
}

export interface FullLevelData {
  name: string
  memberCount: number
  scoreStart: number
  scoreEnd: number
  avgProductCount: number
  memberCountHistoryData: StandardChartData
  movement?: LevelMovement[]
}

export interface FullLevelsChartDataArgument {
  levels: FullLevelData[]
}

export default class FullLevelsChartData extends ChartData {
  readonly levels: FullLevelData[]

  constructor(arg: FullLevelsChartDataArgument) {
    super({ type: ChartDataType.levelsFull })
    this.levels = arg.levels.map((level) => ({
      ...level,
      memberCountHistoryData: new StandardChartData(level.memberCountHistoryData),
    }))
  }

  isEmpty() {
    return this.levels.length === 0
  }

  static getMovementData(levelData: FullLevelData, timeframe: Timeframe) {
    const movementUpIn = levelData.movement?.find(
      (m) => m.timeframe === timeframe && m.movementType === MovementType.upIn,
    )
    const movementUpOut = levelData.movement?.find(
      (m) => m.timeframe === timeframe && m.movementType === MovementType.upOut,
    )
    const movementNew = levelData.movement?.find(
      (m) => m.timeframe === timeframe && m.movementType === MovementType.new,
    )
    const movementDownIn = levelData.movement?.find(
      (m) => m.timeframe === timeframe && m.movementType === MovementType.downIn,
    )
    const movementDownOut = levelData.movement?.find(
      (m) => m.timeframe === timeframe && m.movementType === MovementType.downOut,
    )
    const movementLost = levelData.movement?.find(
      (m) => m.timeframe === timeframe && m.movementType === MovementType.lost,
    )
    if (
      !movementUpIn ||
      !movementUpOut ||
      !movementNew ||
      !movementDownIn ||
      !movementDownOut ||
      !movementLost
    )
      return null
    return {
      [MovementType.upIn]: movementUpIn,
      [MovementType.upOut]: movementUpOut,
      [MovementType.new]: movementNew,
      [MovementType.downIn]: movementDownIn,
      [MovementType.downOut]: movementDownOut,
      [MovementType.lost]: movementLost,
    }
  }

  static getOverallMovement(levelData: FullLevelData, timeframe: Timeframe) {
    const movementData = this.getMovementData(levelData, timeframe)
    console.log(movementData)
    if (!movementData) return { memberChange: 0, percentChange: 0 }
    const memberChange =
      movementData[MovementType.upIn].count +
      movementData[MovementType.downIn].count +
      movementData[MovementType.new].count -
      movementData[MovementType.upOut].count -
      movementData[MovementType.downOut].count -
      movementData[MovementType.lost].count
    const percentChange = (memberChange / levelData.memberCount) * 100
    return {
      memberChange,
      percentChange,
    }
  }
}
