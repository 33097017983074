import { Icon } from '@iconify/react'
import { Stack } from '@mui/material'
import { ReactNode } from 'react'
import icons from '../../config/icons.config'
import palette from '../../theme/palette'

export interface TableSortLabelProps {
  children: ReactNode
  direction: 'asc' | 'desc' | undefined
  onClick: () => void
}

export function TableSortLabel({ children, direction, onClick }: TableSortLabelProps) {
  return (
    <Stack direction='row' alignItems='center'>
      {children}
      {/* Sort icon is a combination of chevron up and chevron down. When the direction is ascending, chevron up is dark. When descending, chevron down is dark. Any other time, both are light-colored. */}
      <Stack sx={{ paddingRight: 1, cursor: 'pointer' }} onClick={onClick}>
        <Icon
          icon={icons.chevron.up}
          color={direction === 'asc' ? palette.light.grey[800] : palette.light.grey[400]}
          width={20}
        />
        <Icon
          icon={icons.chevron.down}
          style={{ marginTop: -10 }}
          color={direction === 'desc' ? palette.light.grey[800] : palette.light.grey[400]}
          width={20}
        />
      </Stack>
    </Stack>
  )
}
