import { UserAnnouncementData } from '../model/announcement/UserAnnouncement'
import DataSource from '../service/domain/DataSource'
import StateService from '../service/domain/StateService'
import { load, save, saveOneInList } from '../util/LoadableHookstateHelpers'

export default class AnnouncementManager {
  readonly dataSource: DataSource
  readonly state: StateService

  constructor(dataSource: DataSource, state: StateService) {
    this.dataSource = dataSource
    this.state = state
  }

  async fetchAnnouncements() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)
    const user = this.state.user.get().data
    if (!user) throw new Error(`No user selected`)

    const fetchFunction = () =>
      this.dataSource.getAllUserAnnouncements(user.userID, client.clientID)
    await load(this.state.userAnnouncementsData, fetchFunction)
  }

  async markAnnouncementAsRead(announcementID: string) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)
    const user = this.state.user.get().data
    if (!user) throw new Error(`No user selected`)

    const markFunction = () =>
      this.dataSource.markAnnouncementAsRead(client.clientID, user.userID, announcementID)
    const matchFunction = (a: UserAnnouncementData) =>
      a.announcement.announcementID === announcementID
    const announcement = this.state.userAnnouncementsData
      .get()
      ?.data?.find((a) => a.announcement.announcementID === announcementID) as UserAnnouncementData
    announcement.isRead = true
    saveOneInList(this.state.userAnnouncementsData, announcement, markFunction, matchFunction)
  }

  async markAllAnnouncementsAsRead() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)
    const user = this.state.user.get().data
    if (!user) throw new Error(`No user selected`)

    const markFunction = () =>
      this.dataSource.markAllUserAnnouncementsAsRead(client.clientID, user.userID)
    const allAnnouncements = this.state.userAnnouncementsData.get()?.data as UserAnnouncementData[]
    for (const announcement of allAnnouncements) {
      announcement.isRead = true
    }
    save(this.state.userAnnouncementsData, allAnnouncements, markFunction)
  }
}
