import { useHookstate } from '@hookstate/core'
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect } from 'react'
import { notificationManager } from '../../managers/_manager.config'
import UserNotificationSetting, {
  NotificationMethod,
  NotificationName,
} from '../../model/notification/UserNotificationSetting'
import globalState from '../../service/external/GlobalState'
import StatusView from '../util/StatusView'

export interface NotificationSettingsViewProps {}

export default function NotificationSettingsView({}: NotificationSettingsViewProps) {
  // -- Global state
  const userNotificationSettingsState = useHookstate(globalState.userNotificationSettings).get()

  // -- Lifecycle
  useEffect(() => {
    if (!userNotificationSettingsState.isLoaded()) notificationManager.fetchNotificationSettings()
  }, [])

  // -- Actions
  function handleUnselectAll() {
    notificationManager.turnOffAllNotifications()
  }

  function toggleMethod(setting: UserNotificationSetting, method: NotificationMethod) {
    const currentValue = setting.methods.includes(method)
    notificationManager.setMethod(setting, method, !currentValue)
  }

  // UI
  const categories = [
    {
      name: 'General',
      description: 'Notifications related to new data loads, AI opportunities and reports.',
      notificationNames: [NotificationName.newDataLoads, NotificationName.newAIOpportunities],
    },
    {
      name: 'Opportunities',
      description: 'Notifications related to opportunities status updates, comments and reports.',
      notificationNames: [
        NotificationName.opportunityComments,
        NotificationName.activeOpportunities,
        NotificationName.evaluatedOpportunities,
        NotificationName.completedOpportunities,
      ],
    },
  ]

  return (
    <Stack>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent={'space-between'}
        marginTop={1}
        marginBottom={0}
        width='100%'
      >
        <Typography variant='body1' fontSize={14} color='textSecondary' sx={{ textAlign: 'start' }}>
          {'Choose how will your notifications reach you.'}
        </Typography>
        <Button onClick={handleUnselectAll}>Unselect all</Button>
      </Stack>
      <StatusView
        state={userNotificationSettingsState}
        render={(userNotificationSettings) => (
          <Stack spacing={2} pt={4}>
            {categories.map((category, catIndex) => (
              <Card key={catIndex} variant='outlined'>
                <CardContent>
                  <Stack direction='row' spacing={3}>
                    <Box width='30%' sx={{ display: 'inline-block', textAlign: 'start' }}>
                      <Typography variant='h6'>{category.name}</Typography>
                      <Typography variant='body1' fontSize={13} color='textSecondary'>
                        {category.description}
                      </Typography>
                    </Box>
                    <Stack width='70%' direction='column' spacing={2}>
                      {category.notificationNames.map((name) => {
                        const setting = userNotificationSettings.find(
                          (s) => s.name === name,
                        ) as UserNotificationSetting
                        if (!setting) return null

                        return (
                          <Stack key={name} direction='row' width='100%' alignItems='center'>
                            <Typography
                              width='50%'
                              variant='h6'
                              fontSize={15}
                              sx={{ textAlign: 'left' }}
                            >
                              {name}
                            </Typography>
                            <Stack
                              direction='row'
                              width='50%'
                              justifyItems='flex-end'
                              justifyContent='flex-end'
                            >
                              <Box width='100%' sx={{ display: 'inline-block', textAlign: 'end' }}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={setting.methods.includes(NotificationMethod.email)}
                                      onChange={() =>
                                        toggleMethod(setting, NotificationMethod.email)
                                      }
                                    />
                                  }
                                  label={
                                    <Typography variant='h6' fontSize={14}>
                                      Email
                                    </Typography>
                                  }
                                />
                              </Box>
                            </Stack>
                          </Stack>
                        )
                      })}
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        )}
      />
    </Stack>
  )
}
