import { useHookstate } from '@hookstate/core'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogContent, Skeleton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useModal } from '../../contexts/ModalContext'
import { exploreManager, opportunityManager } from '../../managers/_manager.config'
import Metric from '../../model/admin/Metric'
import PropensityTarget from '../../model/explore/PropensityTarget'
import Filter from '../../model/filter/Filter'
import MetricData from '../../model/metric/MetricData'
import Opportunity from '../../model/opportunity/Opportunity'
import globalState from '../../service/external/GlobalState'
import FilterChips from '../explore/filter/FilterChips'
import MetricPicker from '../metric/MetricPicker'
import CustomTextFieldController from '../modal/CustomTextFieldController'
import ModalHeader from '../modal/ModalHeader'
import StatusView from '../util/StatusView'

export interface CreateOpportunityModalForm {
  name: string
  selectedMetricName?: string
}

export interface CreateOpportunityModalProps {
  propensityTarget: PropensityTarget
  filters: Filter[]
  split?: boolean
  onCreate: (name: string, metricData?: MetricData) => Promise<void>
  onClose: () => void
}

export default function CreateOpportunityModal({
  propensityTarget,
  filters,
  split = false,
  onCreate,
  onClose,
}: CreateOpportunityModalProps) {
  // -- Navigation
  const { showSnackbar } = useModal()

  // -- Global state
  const opportunitiesState = useHookstate(globalState.opportunities).get()
  const metricsState = useHookstate(globalState.exploreMetrics).get()

  // -- Local state
  const [metricData, setMetricData] = useState<MetricData | null>(null)
  const [isMetricDataLoading, setIsMetricDataLoading] = useState(false)
  const [isCreateLoading, setIsCreateLoading] = useState(false)

  // -- Form
  const {
    control,
    handleSubmit,
    getValues,
    setError,
    watch,
    formState: { errors },
  } = useForm<CreateOpportunityModalForm>()

  // -- Lifecycle
  useEffect(() => {
    opportunityManager.fetchOpportunities()
    exploreManager.fetchMetrics()
  }, [])

  // -- Actions
  async function onSubmit({ name }: CreateOpportunityModalForm) {
    const opportunities = opportunitiesState.data
    if (!opportunities) return

    // Check if opportunity name is unique
    const error = Opportunity.validateName(name, opportunities as Opportunity[])
    if (error) {
      setError('name', { type: 'manual', message: error })
      return
    }

    // If split, check if metric is selected
    if (split && !getValues('selectedMetricName')) {
      setError('selectedMetricName', { type: 'manual', message: 'Metric is required' })
    }

    try {
      setIsCreateLoading(true)
      await onCreate(name, metricData ?? undefined)
      setIsCreateLoading(false)
    } catch (error) {
      showSnackbar('opportunity-create-error', 'Failed to create opportunity', 'error')
      setIsCreateLoading(false)
      return
    }

    onClose()
  }

  async function fetchMetricData() {
    if (!propensityTarget || !metricsState.data) return

    const metric = metricsState.data.find((m) => m.name === getValues('selectedMetricName'))
    if (!metric) return

    setIsMetricDataLoading(true)
    const data = await exploreManager.fetchOneTimeMetricData(
      metric as Metric,
      filters,
      propensityTarget,
    )
    setMetricData(data)
    setIsMetricDataLoading(false)
  }

  // -- UI
  const title = split ? 'Create Opportunities' : 'Create Opportunity'

  function getDescription() {
    function getTargetDescription() {
      return ` for ${propensityTarget.productCategory.name} ${
        filters.length === 0 ? 'targeting all members' : 'targeting members with these attributes:'
      }`
    }

    if (split)
      return `Create multiple marketing opportunities ${getTargetDescription()}, split by a certain metric`
    return `Create a marketing opportunity ${getTargetDescription()}`
  }

  function getCreateMessage() {
    const metricName = getValues('selectedMetricName')
    const opportunityName = watch('name')
    const keys = metricData?.getKeys()
    const opportunityNames =
      keys?.map((key) => Opportunity.getSplitName(opportunityName, key)) ?? []

    if (opportunityNames.length === 0) {
      return (
        <Typography>
          No data available for this metric. No opportunities will be created.
        </Typography>
      )
    }

    return (
      <Stack>
        <Typography variant='body2'>
          Selecting "{metricName}" will split your member group into {opportunityNames.length}{' '}
          opportunities, each with another filter for the given value (ex. a Life Stage = Retired
          opportunity will only include retired members)
        </Typography>
        <Typography mt={1} fontWeight='medium'>
          Opportunities to be created:
        </Typography>
        {opportunityNames.map((name, index) => (
          <Typography key={index}>{name}</Typography>
        ))}
      </Stack>
    )
  }

  return (
    <Dialog open onClose={onClose} maxWidth='sm' fullWidth>
      <Stack gap={2}>
        <ModalHeader title={title} center description={getDescription()} />
        {filters.length > 0 && (
          <FilterChips filters={filters as Filter[]} alignSelf='center' center />
        )}
      </Stack>
      <DialogContent>
        <Stack gap={2} p={2} textAlign='center'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <CustomTextFieldController
                name='name'
                control={control}
                label='Opportunity Name'
                rules={{
                  required: 'Name is required',
                  pattern: {
                    value: /^[^/]*$/,
                    message: `Opportunity name can't contain the character "/"`,
                  },
                }}
                error={errors.name?.message}
                autoFocus
              />
              {split && (
                <Stack gap={2}>
                  <StatusView
                    state={metricsState}
                    render={(metrics) => (
                      <Controller
                        name='selectedMetricName'
                        control={control}
                        render={({ field, fieldState }) => (
                          <MetricPicker
                            availableMetrics={metrics as Metric[]}
                            selectedMetricName={field.value}
                            onMetricClick={(metric) => {
                              field.onChange(metric.name)
                              fetchMetricData()
                            }}
                            error={fieldState.invalid ? 'Metric is required' : undefined}
                          />
                        )}
                      />
                    )}
                  />
                  {isMetricDataLoading ? (
                    <Stack>
                      <Skeleton variant='text' />
                      <Skeleton variant='text' />
                    </Stack>
                  ) : metricData ? (
                    getCreateMessage()
                  ) : null}
                </Stack>
              )}

              <StatusView
                state={opportunitiesState}
                render={() => (
                  <LoadingButton
                    data-testid='opportunity-modal-create-opportunity-btn'
                    type='submit'
                    variant='contained'
                    loading={isCreateLoading}
                  >
                    {title}
                  </LoadingButton>
                )}
              />
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
