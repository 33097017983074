import { Icon } from '@iconify/react'
import { Box, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import { ReactElement, memo } from 'react'
import icons from '../../config/icons.config'

export interface RowActions {
  onOpen?: (id: string) => void
  onEdit?: (id: string) => void
  onDuplicate?: (id: string) => void
  onDelete?: (id: string) => void
  onDeleteMultiple?: (ids: string[]) => void
  additionalActions?: RowAction[]
}

export interface RowAction {
  id: string
  label: string | ((rowIDs: string[]) => string)
  onClick: (ids: string[]) => void
  icon?: JSX.Element
  color?: string
  fontWeight?: string
  permissionID?: string
  hide?: boolean
  disabled?: boolean
  allowsMultiple?: boolean
  requiresMultiple?: boolean
}

export interface RowOptionsMenuProps {
  rowIDs: string[]
  actions: RowActions
  clearSelection?: () => void
  anchorPosition?: { top: number; left: number }
  onClose?: () => void
}

function RowOptionsMenu({
  rowIDs,
  actions,
  clearSelection,
  anchorPosition,
  onClose,
}: RowOptionsMenuProps): ReactElement {
  const theme = useTheme()

  const handleClose = () => {
    onClose?.()
  }

  const isMultipleSelected = rowIDs?.length > 1

  const defaultActions: { [key: string]: RowAction } = {
    open: {
      id: 'open',
      hide: !actions.onOpen,
      label: 'Open',
      onClick: () => actions.onOpen?.(rowIDs[0]),
      icon: <Icon icon={icons.open} width={18} />,
      disabled: isMultipleSelected,
    },
    edit: {
      id: 'edit',
      hide: !actions.onEdit,
      label: 'Edit',
      onClick: () => actions.onEdit?.(rowIDs[0]),
      icon: <Icon icon={icons.edit} width={18} />,
      disabled: isMultipleSelected,
    },
    duplicate: {
      id: 'duplicate',
      hide: !actions.onDuplicate,
      label: 'Duplicate',
      onClick: () => actions.onDuplicate?.(rowIDs[0]),
      icon: <Icon icon={icons.duplicate} width={20} />,
      disabled: isMultipleSelected,
    },
    delete: {
      id: 'delete',
      hide: !actions.onDelete,
      label: (ids) => `Delete ${ids.length > 1 ? `(${ids.length})` : ''}`,
      onClick: () => {
        console.log('delete', rowIDs, isMultipleSelected)
        if (isMultipleSelected) {
          actions.onDeleteMultiple?.(rowIDs)
        } else {
          actions.onDelete?.(rowIDs[0])
        }
      },
      icon: <Icon icon={icons.delete} width={22} />,
      color: theme.palette.error.main,
      allowsMultiple: true,
    },
  }

  const rowActionsList = [
    defaultActions.open,
    defaultActions.edit,
    defaultActions.duplicate,
    ...(actions.additionalActions || []),
    defaultActions.delete,
  ]

  const rowActions: RowAction[] = rowActionsList.map((action) => ({
    ...action,
    hide:
      (isMultipleSelected && !action.allowsMultiple) ||
      (!isMultipleSelected && action.requiresMultiple)
        ? true
        : action.hide,
  }))

  return (
    <div>
      <Menu
        open
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={anchorPosition || { top: 0, left: 0 }}
        autoFocus={false}
      >
        {rowActions.map(
          (action) =>
            !action.hide && (
              <MenuItem
                key={action.id}
                onClick={() => {
                  clearSelection?.()
                  action.onClick(rowIDs)
                  handleClose()
                }}
                disabled={action.disabled}
                data-testid={`row-action-btn-${action.id}`}
              >
                <Box display='flex' alignItems='center' color={action.color}>
                  {action.icon}
                  <Box ml={1}>
                    <Typography>
                      {typeof action.label === 'function' ? action.label(rowIDs) : action.label}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            ),
        )}
      </Menu>
    </div>
  )
}
export default memo(RowOptionsMenu)
