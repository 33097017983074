import { useHookstate } from '@hookstate/core'
import { InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import copy from '../../config/copy.config'
import Opportunity from '../../model/opportunity/Opportunity'
import OpportunityMethod from '../../model/opportunity/OpportunityMethod'
import DateUtil from '../../util/DateUtil'
import InfoTag from '../metric/InfoTag'
import DateRangePicker from '../util/DateRangePicker'
import OpportunityMethodsInput from './OpportunityMethodsInput'
import { opportunityViewState } from './OpportunityView'

export interface OpportunityMarketingDetailsViewProps {
  onStartDateChange: (date: Date) => void
  onEndDateChange: (date: Date) => void
  onMethodsChange: (methods: OpportunityMethod[]) => void
  generateMarketingMethodContent: (method: OpportunityMethod) => Promise<string>
}

export default function OpportunityMarketingDetailsView({
  onStartDateChange,
  onEndDateChange,
  onMethodsChange,
  generateMarketingMethodContent,
}: OpportunityMarketingDetailsViewProps) {
  // Parent state
  const startDate = useHookstate(opportunityViewState.startDate).get()
  const endDate = useHookstate(opportunityViewState.endDate).get()
  const resultsEndDate = useHookstate(opportunityViewState.resultsEndDate).get()

  const isLocked = Opportunity.isLocked(opportunityViewState.status.get())

  // -- Local state
  const [resultsExtension, setResultsExtension] = useState<number>(
    DateUtil.getDifferenceInDays(endDate, resultsEndDate),
  )

  // -- Actions
  function onResultsExtensionChange(value: number) {
    setResultsExtension(value)
    opportunityViewState.resultsEndDate.set(DateUtil.addDays(endDate, value))
  }

  function _onEndDateChange(date: Date | null) {
    if (!date) return

    onEndDateChange(date)
    opportunityViewState.resultsEndDate.set(DateUtil.addDays(date, resultsExtension))
  }

  // Automatically updates end date when start date is after end date
  useEffect(() => {
    if (startDate && endDate && startDate > endDate) {
      onEndDateChange(startDate)
    }
  }, [startDate, endDate, onEndDateChange])

  // -- UI
  return (
    <Stack direction='row' alignSelf='self-start' alignItems='center' width='100%'>
      <Stack gap={2} width={'100%'}>
        {/* Timeline Section */}
        <Stack gap={1}>
          <Typography variant='h5'>Timeline</Typography>
          <Stack direction='row' gap={2}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={(date) => date && onStartDateChange(date)}
              onEndDateChange={(date) => _onEndDateChange(date)}
              disabled={isLocked}
            />
            <TextField
              value={resultsExtension}
              onChange={(e) => onResultsExtensionChange(parseInt(e.target.value))}
              type='number'
              label={
                <Stack direction='row' alignItems='center' gap={1}>
                  {copy.opportunity.view.results.extendedObservationPeriod.title}
                  <InfoTag
                    description={
                      copy.opportunity.view.results.extendedObservationPeriod.description
                    }
                  />
                </Stack>
              }
              variant='outlined'
              InputProps={{
                startAdornment: <InputAdornment position='start'>+</InputAdornment>,
                endAdornment: <InputAdornment position='end'>days</InputAdornment>,
              }}
              disabled={isLocked}
            />
          </Stack>
        </Stack>

        {/* Marketing Methods Section */}
        <Stack gap={1}>
          <Stack>
            <Typography variant='h5'>
              {copy.opportunity.modal.steps.marketingDetails.title}
            </Typography>
            <Typography color='text.secondary'>
              {copy.opportunity.modal.steps.marketingDetails.description}
            </Typography>
          </Stack>
          <OpportunityMethodsInput
            onMethodsChange={onMethodsChange}
            generateMarketingMethodContent={generateMarketingMethodContent}
            minStartDate={startDate}
            maxEndDate={endDate}
            defaultStartDate={startDate}
            defaultEndDate={startDate}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
