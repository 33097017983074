import { Icon } from '@iconify/react'
import { Box, Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import copy from '../../config/copy.config'
import icons from '../../config/icons.config'
import ChartType from '../../model/chart/ChartType'
import GroupedChartData from '../../model/chart/GroupedChartData'
import OpportunityMethod from '../../model/opportunity/OpportunityMethod'
import OpportunityResults from '../../model/opportunity/OpportunityResults'
import PropensityType from '../../model/propensity/PropensityType'
import DateUtil from '../../util/DateUtil'
import EChart from '../chart/Echart'
import Timeline from '../chart/Timeline'
import DynamicMenu from '../util/DynamicMenu'
import StandardCard from '../util/StandardCard'

export enum OpportunityAdoptionMetric {
  ACTUAL = 'Actual',
  ONE_MONTH_HISTORICAL_AVG = '1 Month Historical Average',
  THREE_MONTH_HISTORICAL_AVG = '3 Month Historical Average',
  MATCHING_PERIOD_ONE_YEAR = 'Matching Period One Year Ago',
  APPLIED = 'Applied',
}

export interface OpportunityAdoptionChartProps {
  propensityType: PropensityType
  methods: OpportunityMethod[]
  results: OpportunityResults
}

// Custom order for the metrics chart
// This is used to display the metrics in a specific order
// The order is defined by the index of the metric in the array
class OpportunityAdoptionMetricDisplayOrder {
  [OpportunityAdoptionMetric.ACTUAL] = 0;
  [OpportunityAdoptionMetric.APPLIED] = 1;
  [OpportunityAdoptionMetric.ONE_MONTH_HISTORICAL_AVG] = 2;
  [OpportunityAdoptionMetric.THREE_MONTH_HISTORICAL_AVG] = 3;
  [OpportunityAdoptionMetric.MATCHING_PERIOD_ONE_YEAR] = 4
}

export default function OpportunityAdoptionChart({
  propensityType,
  methods,
  results,
}: OpportunityAdoptionChartProps) {
  // -- Local state
  const [selectedMetrics, setSelectedMetrics] = useState<OpportunityAdoptionMetric[]>([
    OpportunityAdoptionMetric.ACTUAL,
    OpportunityAdoptionMetric.THREE_MONTH_HISTORICAL_AVG,
  ])

  useEffect(() => {
    // Show Applied line if there is at least one applied
    if (results?.growthStats?.applicationsCount && results?.growthStats?.applicationsCount > 0) {
      setSelectedMetrics((prevMetrics) => [...prevMetrics, OpportunityAdoptionMetric.APPLIED])
    }
  }, [results?.growthStats?.applicationsCount])

  // Logic
  function getMethodsTimeline(methods: OpportunityMethod[]) {
    // Sort methods by start date
    const sortedMethods = methods.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())

    return sortedMethods.map((method) => ({
      name: method.name,
      start: method.startDate,
      end: method.startDate.getDate() === method.endDate.getDate() ? undefined : method.endDate,
    }))
  }

  function getChartData(results: OpportunityResults): GroupedChartData {
    // Reorder the groups to match the custom order
    const customOrder = new OpportunityAdoptionMetricDisplayOrder()

    const sortedPoints = results.historyData.points.sort((a, b) => {
      return (
        customOrder[a.group as OpportunityAdoptionMetric] -
        customOrder[b.group as OpportunityAdoptionMetric]
      )
    })

    return new GroupedChartData({ points: sortedPoints })
  }

  // -- Actions
  function onToggleMetric(metric: OpportunityAdoptionMetric) {
    const newMetrics = [...selectedMetrics]
    const index = newMetrics.indexOf(metric)
    if (index === -1) {
      newMetrics.push(metric)
    } else {
      newMetrics.splice(index, 1)
    }
    setSelectedMetrics(newMetrics)
  }

  // -- UI
  return (
    <Box sx={{ height: '100%' }}>
      <StandardCard variant='outlined' sx={{ height: '100%' }}>
        <Stack sx={{ height: '100%' }}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pb: 3 }}
          >
            <Typography variant='h6'>
              Product
              {propensityType === PropensityType.growth ? ' Adoptions ' : ' Churn '}
              Across Opportunity Timeline
            </Typography>
            <DynamicMenu
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              menuComponent={
                <Stack sx={{ px: 2, py: 0.5, gap: 0.5 }}>
                  {Object.values(OpportunityAdoptionMetric)
                    .filter((m) => m !== OpportunityAdoptionMetric.ACTUAL)
                    .map((key) => (
                      <Box sx={{ maxWidth: 300 }}>
                        <FormControlLabel
                          key={key}
                          control={
                            <Checkbox
                              checked={selectedMetrics.includes(key)}
                              onChange={() => onToggleMetric(key as OpportunityAdoptionMetric)}
                              sx={{ alignSelf: 'flex-start' }}
                            />
                          }
                          label={
                            <Stack>
                              <Typography fontWeight='medium'>
                                {copy.opportunity.view.results.adoption.metrics[key].title}
                              </Typography>
                              <Typography variant='body2'>
                                {copy.opportunity.view.results.adoption.metrics[key].description}
                              </Typography>
                            </Stack>
                          }
                        />
                      </Box>
                    ))}
                </Stack>
              }
            >
              <Button endIcon={<Icon icon={icons.chevron.down} />}>
                Historical Comparison Options
              </Button>
            </DynamicMenu>
          </Box>
          <Stack sx={{ height: '100%', position: 'relative' }} gap={1}>
            <EChart
              type={ChartType.multiLine}
              data={getChartData(results)}
              padding={{ left: 50 }}
              keyFormatter={DateUtil.getDateString}
              valueAxis={{ name: 'Opens', nameGap: 35 }}
              keyAxis={{ type: 'time' }}
              // markings={getMethodsTimeline(methods)}
              initiallySelectedLegends={selectedMetrics.map((m) => m.toString())}
              onLegendClick={(metric) => {
                onToggleMetric(metric as OpportunityAdoptionMetric)
              }}
              persistentLegends={[OpportunityAdoptionMetric.ACTUAL]}
            />
            <Timeline
              start={new Date(results.historyData.getKeys()[0])}
              end={
                new Date(results.historyData.getKeys()[results.historyData.getKeys().length - 1])
              }
              events={getMethodsTimeline(methods)}
              sx={{ position: 'absolute', top: 30, bottom: 75, left: 48, right: 8 }}
            />
          </Stack>
        </Stack>
      </StandardCard>
    </Box>
  )
}
