import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Timeframe } from '../../model/chart/Timeframe'
import { toggleStyles } from '../chart/levels/styles'

export interface TimeframePickerProps {
  onChange: (value: Timeframe) => void
  value: Timeframe
}

export default function TimeframePicker({ onChange, value }: TimeframePickerProps) {
  return (
    <ToggleButtonGroup
      size='small'
      value={value}
      exclusive
      onChange={(_, newValue) => onChange(newValue)}
      aria-label='duration'
    >
      <ToggleButton sx={toggleStyles.button} value='1m' aria-label='1 month'>
        1m
      </ToggleButton>
      <ToggleButton sx={toggleStyles.button} value='3m' aria-label='3 months'>
        3m
      </ToggleButton>
      <ToggleButton sx={toggleStyles.button} value='12m' aria-label='12 months'>
        12m
      </ToggleButton>
      <ToggleButton sx={toggleStyles.button} value='ytd' aria-label='year to date'>
        YTD
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
