import { Stack, StackProps } from '@mui/material'
import StatisticData from '../../model/chart/StatisticData'
import StatisticNumber from './StatisticNumber'
import StatisticTitle from './StatisticTitle'

export interface StatisticProps extends StackProps {
  data: StatisticData
  description?: string
  center?: boolean
}

export default function Statistic({ data, description, center, ...props }: StatisticProps) {
  return (
    <Stack {...props} sx={{ alignItems: center ? 'center' : '', ...props.sx }}>
      <StatisticTitle title={data.title} description={description} />
      <StatisticNumber {...data} />
    </Stack>
  )
}
