import { Box, Stack } from '@mui/material'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import AnnouncementsTab from '../components/admin/tabs/AnnouncementsTab'
import ClientTab from '../components/admin/tabs/ClientTab'
import ClientUsersTab from '../components/admin/tabs/ClientUsersTab'
import { default as ClientsTab } from '../components/admin/tabs/ClientsTab'
import DefaultMethodsTab from '../components/admin/tabs/DefaultMethodsTab'
import DefaultRolesTab from '../components/admin/tabs/DefaultRolesTab'
import ExportSettingsTab from '../components/admin/tabs/ExportSettingsTab'
import IntegrationsTab from '../components/admin/tabs/IntegrationsTab'
import MethodsTab from '../components/admin/tabs/MethodsTab'
import PermissionsTab from '../components/admin/tabs/PermissionsTab'
import UsersTab from '../components/admin/tabs/UsersTab'
import SideBar from '../components/navigation/SideBar'

const tabsList = [
  {
    label: 'Global Admin',
    pages: [
      { label: 'Clients', component: <ClientsTab /> },
      { label: 'Users', component: <UsersTab /> },
      { label: 'Default Methods', component: <DefaultMethodsTab /> },
      { label: 'Roles', component: <DefaultRolesTab /> },
      { label: 'Permissions', component: <PermissionsTab /> },
      { label: 'Announcements', component: <AnnouncementsTab /> },
    ],
  },
  {
    label: 'Organization Admin',
    pages: [
      { label: 'Organization', component: <ClientTab /> },
      { label: 'Users', component: <ClientUsersTab /> },
      { label: 'Methods', component: <MethodsTab /> },
      { label: 'Integrations', component: <IntegrationsTab /> },
      { label: 'Default Export', component: <ExportSettingsTab /> },
    ],
  },
]

const getTabID = (section: string, page: string) => `${section}-${page}`

export default function AdminScreen() {
  // Local state
  const [selectedPage, setSelectedPage] = useState<string | null>()
  const [_, setSearchParams] = useSearchParams()

  // -- Logic
  const selectedTabComponent = tabsList.reduce((acc, section) => {
    const page = section.pages.find((page) => getTabID(section.label, page.label) === selectedPage)
    if (page) {
      acc = page.component
    }
    return acc
  }, <></>)

  // -- Actions
  const handleSelectPage = (pageID: string) => {
    setSelectedPage(pageID)
    setSearchParams({})
  }

  // -- UI
  return (
    <Stack direction='row'>
      <SideBar onSelectPage={handleSelectPage} />
      <Box p={2} sx={{ width: '100%' }}>
        {selectedTabComponent}
      </Box>
    </Stack>
  )
}
