import { hookstate } from '@hookstate/core'

import Client from '../../model/admin/Client'
import Level from '../../model/admin/Level'
import Method from '../../model/admin/Method'
import Metric from '../../model/admin/Metric'
import Permission from '../../model/admin/Permission'
import Role from '../../model/admin/Role'
import User from '../../model/admin/User'
import MemberType from '../../model/dashboard/MemberType'
import ProductCategory from '../../model/explore/ProductCategory'
import ProductCategoryRating from '../../model/explore/ProductCategoryRating'
import { EngagementType } from '../../model/explore/PropensityTarget'
import Filter from '../../model/filter/Filter'
import FilterOption from '../../model/filter/FilterOption'
import MetricData from '../../model/metric/MetricData'
import MetricsConfig from '../../model/metric/MetricsConfig'
import Opportunity from '../../model/opportunity/Opportunity'
import OpportunityResults from '../../model/opportunity/OpportunityResults'
import PropensityType from '../../model/propensity/PropensityType'
import LoadableState, { LoadableArray } from '../../util/LoadableState'

import { devtools } from '@hookstate/devtools'
import AdminAnnouncement from '../../model/admin/AdminAnnouncement'
import Integration from '../../model/admin/Integration'
import { UserAnnouncementData } from '../../model/announcement/UserAnnouncement'
import { Timeframe } from '../../model/chart/Timeframe'
import ExploreOpportunityStatus from '../../model/explore/ExploreOpportunityStatus'
import UserNotificationSetting from '../../model/notification/UserNotificationSetting'
import OpportunityLog from '../../model/opportunity/OpportunityLog'

const state = {
  // Admin
  clients: LoadableState.create<Client[]>(),
  currentClient: localStorage.getItem('client')
    ? new Client(JSON.parse(localStorage.getItem('client')!))
    : (undefined as Client | undefined),
  users: LoadableState.create<User[]>(),
  user: LoadableState.create<User>(),
  defaultMethods: LoadableState.create<Method[]>(),
  methods: LoadableState.create<Method[]>(),
  roles: LoadableState.create<Role[]>(),
  permissions: LoadableState.create<Permission[]>(),
  levels: LoadableState.create<Level[]>(),
  productCategories: LoadableState.create<ProductCategory[]>(),
  integrations: LoadableState.create<Integration[]>(),
  adminAnnouncements: LoadableState.create<AdminAnnouncement[]>(),

  // Dashboard
  dashboardConfigs: LoadableState.create<MetricsConfig[]>(),
  currentDashboardConfig: undefined as MetricsConfig | undefined,
  dashboardMetrics: LoadableState.create<Metric[]>(),
  dashboardMetricsData: LoadableArray.create<MetricData>([]),
  isEditingDashboard: false,
  dashboardMemberType: MemberType.all,
  dashboardTimeframe: Timeframe.ytd,

  // Explore
  exploreFilters: [] as Filter[],
  exploreFilterOptions: [] as FilterOption[],
  exploreProductCategoryID: undefined as string | undefined,
  explorePropensityType: PropensityType.growth,
  exploreEngagementType: EngagementType.all,
  exploreConfigs: LoadableState.create<MetricsConfig[]>(),
  currentExploreConfig: undefined as MetricsConfig | undefined,
  exploreMetrics: LoadableState.create<Metric[]>(),
  exploreMetricsData: LoadableArray.create<MetricData>([]),
  productCategoryRatings: LoadableState.create<ProductCategoryRating[]>(),
  isEditingExplore: false,
  exploreOpportunity: undefined as Opportunity | undefined,
  exploreOpportunityStatus: ExploreOpportunityStatus.none,

  // Opportunity
  opportunities: LoadableState.create<Opportunity[]>(),
  currentOpportunityResults: LoadableState.create<OpportunityResults | null>(),
  currentOpportunityLogs: LoadableState.create<OpportunityLog[]>(),

  // Metric
  metrics: LoadableState.create<Metric[]>(),
  metricsData: LoadableArray.create<MetricData>([]),

  // Announcement
  userAnnouncementsData: LoadableState.create<UserAnnouncementData[]>(),

  // Notifications
  userNotificationSettings: LoadableState.create<UserNotificationSetting[]>(),
}

export const initialState = { ...state }

const globalState = hookstate(state, devtools({ key: 'global-state' }))

export default globalState
