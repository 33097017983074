import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import {
  Avatar,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  Slide,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { memo, useEffect, useRef, useState } from 'react'
import icons from '../../../config/icons.config'
import { adminManager, opportunityManager } from '../../../managers/_manager.config'
import User from '../../../model/admin/User'
import Opportunity from '../../../model/opportunity/Opportunity'
import OpportunityLog from '../../../model/opportunity/OpportunityLog'
import globalState from '../../../service/external/GlobalState'
import palette from '../../../theme/palette'
import DateUtil from '../../../util/DateUtil'
import LoadingIndicator from '../../util/LoadingIndicator'
import { ChatInput } from './ChatInput'

type ChatViewProps = {
  user: User | null
  opportunity: Opportunity
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  discussionLogs: OpportunityLog[]
  setDiscussionLogs: React.Dispatch<React.SetStateAction<OpportunityLog[]>>
}

const MessageElement = (log: OpportunityLog, isOutgoing: boolean, users: any) => {
  const theme = useTheme()
  const taggedUser = users?.find((user: any) => {
    return log.content.includes(user.email)
  })

  const renderContent = () => {
    if (taggedUser) {
      const parts = log.content.split(taggedUser.email)
      return (
        <>
          {parts[0]}
          <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            {taggedUser.firstName} {taggedUser.lastName}
          </span>
          {parts[1]}
        </>
      )
    }
    return log.content
  }

  return (
    <ListItem
      key={log.logID}
      sx={{
        width: '100%',
        flexDirection: isOutgoing ? 'row-reverse' : 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 1,
      }}
    >
      {log.userDisplayName && (
        <Tooltip title={log.userDisplayName}>
          <Avatar
            sx={{
              fontSize: 16,
              bgcolor: palette.light.grey[50016],
            }}
          >
            {log.userDisplayName
              .split(' ')
              .map((name) => name[0])
              .join('')}
          </Avatar>
        </Tooltip>
      )}
      <Stack direction='column' alignItems={isOutgoing ? 'flex-end' : 'flex-start'}>
        <Chip
          variant={isOutgoing ? 'filled' : 'outlined'}
          label={<span>{renderContent()}</span>}
          sx={{
            maxWidth: 325,
            width: 'fit-content',
            height: 'fit-content',
            marginBottom: 0.5,
            '& .MuiChip-label': {
              display: 'block',
              whiteSpace: 'normal',
              overflowWrap: 'break-word',
              padding: 0,
              fontSize: 14,
              color: isOutgoing ? theme.palette.primary.contrastText : theme.palette.text.primary,
            },
            paddingX: 1.75,
            paddingY: 1.5,
            background: isOutgoing ? theme.palette.primary.main : theme.palette.background.paper,
          }}
        />
        <Typography
          variant='body1'
          sx={{
            fontSize: 12,
            color: theme.palette.text.secondary,
            marginLeft: isOutgoing ? 0 : 0.5,
            marginRight: isOutgoing ? 0.5 : 0,
          }}
        >
          {DateUtil.formatDateTime(log.timestamp, true)}
        </Typography>
      </Stack>
    </ListItem>
  )
}

const SystemLogElement = (log: OpportunityLog) => {
  const theme = useTheme()

  return (
    <ListItem
      key={log.logID}
      sx={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Divider
        sx={{
          width: '100%',
          '&::before, &::after': {
            // borderColor: theme.palette.grey[400],
            borderWidth: 1.5,
          },
        }}
      >
        <Chip
          variant='filled'
          label={log.content}
          sx={{
            maxWidth: 325,
            height: 'fit-content',
            marginY: 0.75,
            borderRadius: 1.5,
            '& .MuiChip-label': {
              display: 'block',
              textAlign: 'center',
              padding: 0,
              fontSize: 12,
              color: theme.palette.grey[600],
            },
            paddingX: 1.75,
            paddingY: 1,
          }}
        />
      </Divider>
      <Typography variant='body1' sx={{ fontSize: 10, color: theme.palette.text.secondary }}>
        {DateUtil.formatDateTime(log.timestamp, true)}
      </Typography>
    </ListItem>
  )
}

export const ChatView = memo(function ChatView(props: ChatViewProps) {
  const listRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const usersState = useHookstate(globalState.users).get()
  const users = usersState.data

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [listRef.current, props.discussionLogs, props.show])

  useEffect(() => {
    adminManager.fetchUsers()
  }, [])

  const ChatHeader = styled(Stack)(({ theme }) => ({
    background: theme.palette.background.default,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  }))

  const sendMessage = async (text: string) => {
    if (!text.trim()) return
    setLoading(true)
    const newLog = await opportunityManager.saveOpportunityLog(
      props.opportunity.opportunityID,
      text,
    )
    props.setDiscussionLogs([...props.discussionLogs, newLog])
    setLoading(false)
  }

  return (
    <Slide direction='left' in={props.show} mountOnEnter unmountOnExit>
      <Stack
        sx={{
          width: 450,
          height: 'calc(100vh - 52px)',
          background: theme.palette.grey[300],
          overflow: 'hidden',
          position: 'absolute',
          bottom: -0.5,
          right: 0,
          zIndex: 51,
          borderRadius: 1.5,
        }}
      >
        <ChatHeader direction='row' paddingY={2.5} paddingX={3}>
          <Stack height='fit-content'>
            <Typography variant='body1' fontWeight={600} fontSize={18} noWrap maxWidth={325}>
              {props.opportunity.name}
            </Typography>
            <Typography variant='body2' noWrap>
              Opportunity Discussion Board
            </Typography>
          </Stack>
          <IconButton onClick={() => props.setShow(false)}>
            <Icon icon={icons.closeSimple} width={20} height={20} />
          </IconButton>
        </ChatHeader>
        <Stack height='100%' overflow='auto'>
          <List>
            {props.discussionLogs.map((log) =>
              log.isSystemLog
                ? SystemLogElement(log)
                : MessageElement(log, log.userID === props.user?.userID, users),
            )}
            {loading && (
              <ListItem
                sx={{
                  width: '100%',
                  justifyContent: 'flex-end',
                  marginRight: 3,
                  marginBottom: 1.5,
                }}
              >
                <LoadingIndicator />
              </ListItem>
            )}
            <div ref={listRef} />
          </List>
        </Stack>
        <ChatInput sendMessage={sendMessage} loading={loading} />
      </Stack>
    </Slide>
  )
})
