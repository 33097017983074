import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ColorUtil from '../../../util/ColorUtil'

import FullLevelsChartData from '../../../model/chart/FullLevelsChartData'
import Pyramid from '../Pyramid'
import { AverageProduct } from './AverageProduct'
import { ExploreButton } from './ExploreButton'
import { LineChart } from './LineChart'
import { MemberLevel } from './MemberLevel'
import { MovementIndicator } from './MovementIndicator'
import { FIXED_HEIGHT, styles } from './styles'

import mountain from '../../../assets/mountain.svg'
import { Timeframe } from '../../../model/chart/Timeframe'

interface TableRowsProps {
  data: FullLevelsChartData
  normalizedValues: number[]
  timeframe: Timeframe
}

export function TableRows({ data, normalizedValues, timeframe }: TableRowsProps) {
  const theme = useTheme()
  const colorScheme = ColorUtil.generateColorScheme(theme.palette.primary.main, data.levels.length)

  return (
    <Grid container>
      <Grid
        item
        xs={2.25}
        sx={{
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 4,
            bottom: 0,
            left: 0,
            backgroundImage: `url(${mountain})`,
            backgroundSize: 'cover',
            opacity: 0.3,
          },
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        {normalizedValues.map((normalizedValue, index) => (
          <Grid key={`pyramid-${index}`} sx={{ ...FIXED_HEIGHT, ...styles.cellStyles }}>
            <Pyramid value={normalizedValue} color={colorScheme[index]} />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={1.5}>
        {data.levels.map((levelData, index) => (
          <MemberLevel key={index} data={levelData} />
        ))}
      </Grid>
      <Grid item xs={3}>
        {data.levels.map((levelData, index) => (
          <MovementIndicator key={`growth-${index}`} levelData={levelData} timeframe={timeframe} />
        ))}
      </Grid>
      <Grid item xs={2}>
        {data.levels.map((levelData, index) => (
          <AverageProduct key={index} data={levelData} />
        ))}
      </Grid>
      <Grid item xs={3}>
        {data.levels.map((levelData, index) => (
          <LineChart key={`growth-${index}`} data={levelData.memberCountHistoryData} />
        ))}
      </Grid>
      <Grid item xs={0.25}>
        {data.levels.map((levelData, index) => (
          <ExploreButton data={levelData} key={`growth-${index}`} />
        ))}
      </Grid>
    </Grid>
  )
}
