import { Button, Stack, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

import { useHookstate } from '@hookstate/core'
import { useEffect, useState } from 'react'
import verifyAccountImage from '../../assets/verify-account.svg'
import AuthFlowLayout from '../../components/layout/AuthFlowLayout'
import LoadingIndicator from '../../components/util/LoadingIndicator'
import VerificationCodeInput from '../../components/util/VerificationCodeInput'
import { authManager } from '../../managers/_manager.config'
import User from '../../model/admin/User'
import { ScreenPath, getHomeScreenPath } from '../../navigation'
import globalState from '../../service/external/GlobalState'
import { ResetPasswordState } from './ResetPasswordScreen'

export enum VerificationType {
  resetPassword = 'email',
  login = 'login',
}

interface VerifyLoginProps {
  verificationType: VerificationType.login
  username: string
  password: string
}

interface VerifyResetPasswordProps {
  verificationType: VerificationType.resetPassword
  email: string
}

function VerifyAccountScreen() {
  // Navigation
  const navigate = useNavigate()
  const location = useLocation()
  const navigationState = getNavigationState(location.state)
  const userState = useHookstate(globalState.user).get()
  const user = userState.data as User | undefined

  // -- Local state
  const [isLoading, setIsLoading] = useState(false)
  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<string | undefined>(undefined)
  const fromPath = location.state?.fromPath;

  // -- Lifecycle
  useEffect(() => {
    // When the user is set, go to home screen (we need to do this b/c the home screen we go to depends on the user)
    if (user) {
      if (fromPath) {
        navigate(fromPath)
      } else {
        navigate(getHomeScreenPath(user))
      }
    }
  }, [user])

  // Logic
  function getNavigationState(state: any) {
    const verificationType = state?.verificationType
    switch (verificationType) {
      case VerificationType.login:
        return {
          verificationType,
          username: state!.username,
          password: state?.password,
        } as VerifyLoginProps
      case VerificationType.resetPassword:
        return {
          verificationType,
          email: state?.email,
        } as VerifyResetPasswordProps
      default:
        throw new Error('Invalid verification type')
    }
  }

  async function handleSubmit(code: string) {
    setIsLoading(true)

    switch (navigationState.verificationType) {
      case VerificationType.login:
        try {
          await authManager.verifyMFACode(code)
          setIsLoading(false)
        } catch (error) {
          setIsLoading(false)
          setError(`The code you entered is incorrect. Please try again.`)
        }
        break
      case VerificationType.resetPassword:
        navigate(ScreenPath.resetPassword, {
          state: {
            resetPasswordCode: code,
            resetPasswordEmail: navigationState.email,
            resetPasswordState: ResetPasswordState.reset,
          },
        })
    }

    setIsLoading(false)
  }

  function onVerificationResend() {
    switch (navigationState.verificationType) {
      case VerificationType.login:
        authManager.login(navigationState.username, navigationState.password)
        break
      case VerificationType.resetPassword:
        authManager.sendResetPasswordCode(navigationState.email)
        break
    }
  }

  function handleCancel() {
    navigate(ScreenPath.login)
  }

  function handleResend() {
    onVerificationResend?.()
  }

  function handleCodeChange(code: string) {
    setCode(code)
    setError(undefined)
  }

  return (
    <AuthFlowLayout title='Verify your account' image={verifyAccountImage}>
      <Typography>
        {`We just sent a 6-digit code to your MFA device. Enter the code below to confirm your account`}
      </Typography>
      <Stack spacing={3}>
        <div>
          {isLoading && <LoadingIndicator />}
          <div className='flex justify-center' style={{ display: isLoading ? 'none' : '' }}>
            <VerificationCodeInput
              length={6}
              value={code}
              onCompleted={handleSubmit}
              onChange={handleCodeChange}
              autoFocus
              error={error}
            />
          </div>
        </div>
        <Stack direction='row' spacing={2}>
          <Button onClick={handleCancel} fullWidth>
            Cancel
          </Button>
          <Button onClick={handleResend} variant='contained' fullWidth>
            Resend
          </Button>
        </Stack>
      </Stack>
    </AuthFlowLayout>
  )
}

export default VerifyAccountScreen
