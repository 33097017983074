import { Icon } from '@iconify/react'
import { LoadingButton } from '@mui/lab'
import { Link, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import AuthFlowLayout from '../../components/layout/AuthFlowLayout'
import FormController from '../../components/util/FormController'
import PasswordInput from '../../components/util/PasswordInput'
import icons from '../../config/icons.config'
import { useModal } from '../../contexts/ModalContext'
import { authManager } from '../../managers/_manager.config'
import { ScreenPath } from '../../navigation'
import { ResetPasswordState } from './ResetPasswordScreen'
import { VerificationType } from './VerifyAccountScreen'

export interface LoginFormData {
  username: string
  password: string
}

function LoginScreen() {
  const navigate = useNavigate()
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false)
  const { handleSubmit, control, setError, watch } = useForm<LoginFormData>()
  const { showSnackbar } = useModal()

  async function handleLogin({ username, password }: LoginFormData) {
    try {
      setIsLoading(true)
      await authManager.login(username, password)
      setIsLoading(false)
      const fromPath = location.state?.from?.pathname
      navigate(ScreenPath.verifyAccount, {
        state: {
          verificationType: VerificationType.login,
          username,
          password,
          fromPath,
        },
      })
    } catch (error) {
      setIsLoading(false)

      if (error === 'InitialPasswordRequired') {
        navigate(ScreenPath.resetPassword, {
          state: {
            resetPasswordState: ResetPasswordState.initial,
          },
        })
        return
      }

      setError('username', {
        type: 'manual',
      })
      setError('password', {
        type: 'manual',
      })

      const formErrors = control._formState.errors
      if (formErrors.username) {
        showSnackbar('loginError', 'Invalid Username/Email', 'error')
      } else if (formErrors.password) {
        showSnackbar('loginError', 'Invalid password', 'error')
      } else {
        showSnackbar('loginError', 'Login failed', 'error')
      }
    }
  }

  return (
    <AuthFlowLayout title='Login'>
      <form onSubmit={handleSubmit(handleLogin)}>
        <Stack gap={1}>
          <FormController
            name='username'
            control={control}
            rules={{ require }}
            placeholder='Username/email'
            icon={<Icon icon={icons.person} width={20} />}
          />
          <PasswordInput
            control={control}
            name='password'
            placeholder='Password'
            rules={{ require }}
          />
          <div className='text-start'>
            <Link href={ScreenPath.forgotPassword} underline='hover'>
              Forgot password
            </Link>
          </div>
          <LoadingButton
            type='submit'
            variant='contained'
            fullWidth
            sx={{ mt: 3 }}
            disabled={isLoading || !watch('username') || !watch('password')}
            loading={isLoading}
          >
            Login
          </LoadingButton>
          <Typography variant='body2' textAlign='center' mt={2}>
            The intersection of marketing and AI
          </Typography>
        </Stack>
      </form>
    </AuthFlowLayout>
  )
}

export default LoginScreen
