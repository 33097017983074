import { useHookstate } from '@hookstate/core'
import { Icon } from '@iconify/react'
import { useEffect } from 'react'
import icons from '../../../config/icons.config'
import { useModal } from '../../../contexts/ModalContext'
import { adminManager, notificationManager } from '../../../managers/_manager.config'
import Client from '../../../model/admin/Client'
import globalState from '../../../service/external/GlobalState'
import StatusView from '../../util/StatusView'
import AdminTabView, { AdminTab } from '../AdminTabView'
import ClientModal from '../modals/ClientModal'
import DeleteModal from '../modals/DeleteModal'

export default function ClientsTabView() {
  // Navigation
  const { openModal, closeModal, showSnackbar } = useModal()

  // Global state
  const currentClient = useHookstate(globalState.clients).get()

  // Effect
  useEffect(() => {
    adminManager.fetchClients()
  }, [])

  // -- Actions
  const handleCreate = () => {
    const client = Client.create()
    const clients = currentClient.data
    if (!clients) return
    openModal(
      client.clientID,
      <ClientModal
        isCreating
        client={client}
        clients={clients as Client[]}
        onClose={() => closeModal(client.clientID)}
        onSubmit={async (client) => {
          await adminManager.createClient(client)
          closeModal(client.clientID)
          showSnackbar('client-created', 'Client created')
        }}
      />,
    )
  }

  const handleEdit = (clientID: string) => {
    const clients = currentClient.data
    if (!clients) return
    const client = clients?.find((client) => client.clientID === clientID)
    if (!client) return
    const modalID = 'create-client'
    openModal(
      modalID,
      <ClientModal
        client={client as Client}
        clients={clients as Client[]}
        onClose={() => closeModal(modalID)}
        onSubmit={(updatedClient) => {
          adminManager.saveClient(updatedClient)
          closeModal(modalID)
          showSnackbar('client-updated', 'Client updated')
        }}
      />,
    )
  }

  const handleDelete = (clientID: string) => {
    openModal(
      clientID,
      <DeleteModal
        onClose={() => closeModal(clientID)}
        onConfirm={() => {
          adminManager.deleteClient(clientID)
          showSnackbar('client-deleted', 'Client deleted')
        }}
        label='client'
      />,
    )
  }

  function onClearCache(clientID: string) {
    try {
      adminManager.clearCache(clientID)
      showSnackbar('cache-cleared', 'Cache cleared')
    } catch (error) {
      showSnackbar('cache-cleared-error', 'Failed to clear cache', 'error')
    }
  }

  // function onGenerateCache() {
  //   try {
  //     adminManager.generateCache()
  //     showSnackbar('cache-generated', 'Cache generation started')
  //   } catch (error) {
  //     showSnackbar('cache-generated-error', 'Failed to start generating cache', 'error')
  //   }
  // }

  async function onPushToProd(clientID: string) {
    try {
      await adminManager.pushToProd(clientID)
      showSnackbar('push-started', 'Push to production started')
    } catch (error) {
      showSnackbar('push-error', 'Failed to start push to production', 'error')
    }
  }

  async function onSendPushCompleteNotification(clientID: string) {
    try {
      await notificationManager.sendPushCompleteNotification(clientID)
      showSnackbar('push-complete-notification-sent', 'Push complete notification sent')
    } catch (error) {
      showSnackbar(
        'push-complete-notification-error',
        'Failed to send push complete notification',
        'error',
      )
    }
  }

  // -- UI
  const ClientsTab: AdminTab<Client> = {
    label: 'Client',
    pluralLabel: 'Clients',
    columns: [
      { name: 'Client ID', valueGetter: (record) => record.clientID, width: 400 },
      { name: 'Display Name', valueGetter: (record) => record.displayName },
      { name: 'Name', valueGetter: (record) => record.name },
      {
        name: 'Location',
        valueGetter: (record) => `${record.city}, ${record.state} ${record.zip}`,
      },
    ],
    searchFilter: (searchText, record) =>
      record.displayName.toLowerCase().includes(searchText.toLowerCase()),
    rowKey: (record) => record.clientID,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
    additionalActions: [
      {
        id: 'clear-cache',
        label: 'Clear Cache',
        icon: <Icon icon={icons.close} width={18} />,
        onClick: (clientIDs) => onClearCache(clientIDs[0]),
      },
      {
        id: 'push-to-prod',
        label: 'Push to Production',
        icon: <Icon icon={icons.push} width={18} />,
        onClick: (clientIDs) => onPushToProd(clientIDs[0]),
      },
      {
        id: 'send-push-complete-notification',
        label: 'Send Push Complete Notification',
        icon: <Icon icon={icons.notification} width={18} />,
        onClick: (clientIDs) => onSendPushCompleteNotification(clientIDs[0]),
      },
    ],
  }

  return (
    <StatusView
      state={currentClient}
      render={(clients) => <AdminTabView data={clients as Client[]} tab={ClientsTab} />}
    />
  )
}
