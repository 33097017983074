import { Icon } from '@iconify/react'
import { Grid, IconButton, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import icons from '../../../config/icons.config'
import { exploreManager } from '../../../managers/_manager.config'
import { FullLevelData } from '../../../model/chart/FullLevelsChartData'
import { ScreenPath } from '../../../navigation'
import { FIXED_HEIGHT, styles } from './styles'

export function ExploreButton({ data }: { data: FullLevelData }) {
  const navigate = useNavigate()

  const onButtonClick = () => {
    exploreManager.setLevelFilters(data.name)
    navigate(`${ScreenPath.explore}/members`)
  }

  return (
    <Grid sx={{ ...styles.cellStyles, ...styles.centeredFlex, ...FIXED_HEIGHT }}>
      <Tooltip title='Explore members' placement='top'>
        <IconButton sx={{ padding: 1, justifyContent: 'center' }} onClick={onButtonClick}>
          <Icon icon={icons.caret} width={24} />
        </IconButton>
      </Tooltip>
    </Grid>
  )
}
