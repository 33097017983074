import { ImmutableObject } from '@hookstate/core'
import { Layout, Responsive, WidthProvider } from 'react-grid-layout'

import { createContext, useContext, useState } from 'react'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import '../../css/grid-layout.css'
import { DynamicGridItem } from '../../model/metric/GridItem'

const ReactGridLayout = WidthProvider(Responsive)

type DynamicGridItemContextType = {
  key: number // ReactGridItems key that's specific to the item using its context
  currentKey: number // ReactGridItems key of the element that's currently being resized
  resizeKey: number
}
const DynamicGridItemContext = createContext<DynamicGridItemContextType>({
  key: -1,
  currentKey: -1,
  resizeKey: Date.now(),
})
export const useDynamicGridItemContext = () => useContext(DynamicGridItemContext)

export interface DynamicGridProps {
  items: DynamicGridItem[]
  renderItem: (id: string) => React.ReactNode
  isEditing?: boolean
  cols?: { lg: number; md: number; sm: number; xs: number }
  rowHeight?: number
  onGridItemsChange?: (items: DynamicGridItem[]) => void
}

export default function DynamicGrid({
  items,
  renderItem,
  isEditing = false,
  cols = { lg: 20, md: 20, sm: 20, xs: 20 },
  rowHeight = 25,
  onGridItemsChange,
}: DynamicGridProps) {
  // resizeKey is a timestamp used to trigger resize of child EChart whenever the EChart's parent Resizable element is resized
  const [resizeKey, setResizeKey] = useState(0)
  // The ReactGridLayout key of the item currently being resized, which is passed to the child EChart via context
  const [currentKey, setCurrentKey] = useState(-1)

  function generateLayout(items: ImmutableObject<DynamicGridItem[]>, cols: number) {
    const layout = []
    for (let i = 0; i < items.length; i++) {
      const card = items[i]
      const width = card.gridWidth === 'full' ? Math.max(cols, card.minGridWidth) : card.gridWidth
      const height = card.gridHeight
      layout.push({
        i: i.toString(),
        x: card.gridX,
        y: card.gridY,
        w: width,
        h: height,
        minW: card.minGridWidth,
        minH: card.minGridHeight,
      })
    }
    return layout
  }

  function onLayoutChange(layout: Layout[]) {
    if (!onGridItemsChange) return
    const newItems = items.map((item, index) => {
      const layoutItem = layout[index]
      return {
        ...item,
        gridX: layoutItem.x,
        gridY: layoutItem.y,
        gridWidth: layoutItem.w,
        gridHeight: layoutItem.h,
      }
    })
    onGridItemsChange(newItems)
  }

  return (
    <ReactGridLayout
      style={{ userSelect: 'none' }}
      containerPadding={[0, 0]}
      layouts={{
        lg: generateLayout(items, cols.lg),
        md: generateLayout(items, cols.md),
        sm: generateLayout(items, cols.sm),
        xs: generateLayout(items, cols.xs),
      }}
      isResizable={isEditing}
      isDraggable={isEditing}
      cols={cols}
      rowHeight={rowHeight}
      breakpoints={{
        lg: cols.lg * 100,
        md: cols.md * 100,
        sm: cols.sm * 100,
        xs: cols.xs * 100,
      }}
      onLayoutChange={onLayoutChange}
      onResizeStop={(_, oldItem) => {
        setCurrentKey(+oldItem.i)
        setResizeKey(Date.now())
      }}
    >
      {items.map((item, index) => {
        return (
          <div key={index}>
            <DynamicGridItemContext.Provider value={{ key: index, currentKey, resizeKey }}>
              {renderItem(item.id)}
            </DynamicGridItemContext.Provider>
          </div>
        )
      })}
    </ReactGridLayout>
  )
}
