import { Grid, Typography } from '@mui/material'
import { FullLevelData } from '../../../model/chart/FullLevelsChartData'
import StringUtil from '../../../util/StringUtil'
import { FIXED_HEIGHT, styles } from './styles'

export function MemberLevel({ data }: { data: FullLevelData }) {
  const { name, scoreStart, scoreEnd, memberCount } = data
  const mppRange = `${scoreStart}-${scoreEnd}`

  return (
    <Grid sx={{ ...FIXED_HEIGHT, ...styles.cellStyles }} direction='row'>
      <Typography style={styles.levelName}>{name}</Typography>
      <Grid item container direction='column' spacing={0}>
        <Typography variant='subtitle2'>{StringUtil.numberFormat(memberCount)} Members</Typography>
        <Typography color='text.secondary' variant='body2'>
          {mppRange} MPP
        </Typography>
      </Grid>
    </Grid>
  )
}
