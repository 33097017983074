import UserNotificationSetting, {
  NotificationMethod,
} from '../model/notification/UserNotificationSetting'
import DataSource from '../service/domain/DataSource'
import StateService from '../service/domain/StateService'
import { load, saveOneInList } from '../util/LoadableHookstateHelpers'

export default class NotificationManager {
  readonly dataSource: DataSource
  readonly state: StateService

  constructor(dataSource: DataSource, state: StateService) {
    this.dataSource = dataSource
    this.state = state
  }

  async fetchNotificationSettings() {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)
    const user = this.state.user.get().data
    if (!user) throw new Error(`No user`)

    const fetchFunction = () =>
      this.dataSource.fetchUserNotificationSettings(client.clientID, user.userID)
    await load(this.state.userNotificationSettings, fetchFunction)
  }

  async saveNotificationSetting(setting: UserNotificationSetting) {
    const client = this.state.currentClient.get()
    if (!client) throw new Error(`No client selected`)
    const user = this.state.user.get().data
    if (!user) throw new Error(`No user`)

    const saveFunction = () =>
      this.dataSource.saveUserNotificationSetting(client.clientID, user.userID, setting)
    const matchFunction = (s: UserNotificationSetting) =>
      s.userID === setting.userID && s.clientID === setting.clientID && s.name === setting.name

    saveOneInList(this.state.userNotificationSettings, setting, saveFunction, matchFunction)
  }

  turnOffAllNotifications() {
    const settings = this.state.userNotificationSettings.data.get() as UserNotificationSetting[]

    for (const setting of settings) {
      this.setMethod(setting, NotificationMethod.email, false)
    }
  }

  async setMethod(setting: UserNotificationSetting, method: NotificationMethod, value: boolean) {
    setting.setMethod(method, value)
    await this.saveNotificationSetting(setting)
  }

  async sendPushCompleteNotification(clientID: string) {
    await this.dataSource.sendPushCompleteNotification(clientID)
  }
}
