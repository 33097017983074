import { Typography } from '@mui/material'
import { FullLevelData } from '../../../model/chart/FullLevelsChartData'
import StringUtil from '../../../util/StringUtil'
import { FIXED_HEIGHT, styles } from './styles'

export function AverageProduct({ data }: { data: FullLevelData }) {
  return (
    <Typography
      sx={{ ...styles.cellStyles, ...FIXED_HEIGHT }}
      fontSize='20px'
      color={'text.secondary'}
      fontWeight={700}
    >
      {StringUtil.numberFormat(data.avgProductCount, 1)}
    </Typography>
  )
}
