import ChartType from '../model/chart/ChartType'

const icons = {
  admin: 'icon-park-solid:setting-two',
  ai: 'mdi:robot-happy',
  atm: 'hugeicons:atm-02',
  announcement: 'ri:megaphone-fill',
  back: 'typcn:arrow-back',
  bank: 'mdi:bank',
  calendar: 'ph:calendar-fill',
  caret: 'radix-icons:caret-right',
  check: 'mingcute:check-fill',
  chevron: {
    up: 'eva:chevron-up-outline',
    down: 'eva:chevron-down-outline',
  },
  charts: {
    [ChartType.bar]: 'material-symbols:bar-chart',
    [ChartType.horizontalBar]: 'ri:bar-chart-horizontal-fill',
    [ChartType.groupedBar]: 'ci:bar-chart-horizontal',
    [ChartType.stackedBar]: 'carbon:chart-bar-stacked',
    [ChartType.donut]: 'tabler:chart-donut-filled',
    [ChartType.line]: 'material-symbols:show-chart',
    [ChartType.multiLine]: 'material-symbols:show-chart',
    [ChartType.multiStatistic]: '',
    [ChartType.pie]: 'tabler:chart-pie-filled',
    [ChartType.sankey]: '',
    [ChartType.statistic]: '',
    [ChartType.treemap]: 'fluent:data-treemap-24-filled',
    [ChartType.levels]: '',
    [ChartType.levelsFull]: '',
    [ChartType.summitRate]: '',
    [ChartType.timeframe]: '',
    [ChartType.densityMap]: '',
    [ChartType.groupedDensityMap]: '',
  },
  churn: 'line-md:chevron-small-triple-down',
  clock: 'mingcute:time-line',
  circle: 'system-uicons:circle',
  close: 'carbon:close-filled',
  closeSimple: 'ic:round-close',
  dashboard: 'ic:round-dashboard',
  delete: 'mingcute:delete-2-line',
  discussion: 'octicon:comment-discussion-16',
  distance: 'material-symbols:distance-outline',
  dollar: 'mdi:dollar',
  dots: 'tabler:dots',
  download: 'lucide:download',
  duplicate: 'heroicons-outline:duplicate',
  edit: 'fluent:edit-28-filled',
  email: 'ic:email',
  error: 'bx:error',
  explore: 'material-symbols:explore',
  export: 'ph:export-bold',
  eye: 'iconamoon:eye-bold',
  eyeOff: 'iconamoon:eye-off-bold',
  faceSad: 'mdi:face-sad-outline',
  faceHappy: 'mdi:face-happy-outline',
  faceNeutral: 'mdi:face-neutral-outline',
  filter: 'mdi:filter',
  file: 'iconamoon:file-document-fill',
  fruit: 'mdi:fruit-grapes',
  fullscreen: 'gridicons:fullscreen',
  global: 'ant-design:global-outlined',
  growth: 'line-md:chevron-small-triple-up',
  health: 'solar:health-bold',
  info: 'bi:info',
  integration: 'mdi:cloud-sync',
  invert: 'oui:invert',
  key: 'fluent-mdl2:permissions-solid',
  list: 'fluent:list-16-filled',
  lock: 'ic:lock',
  megaphone: 'fluent-emoji-high-contrast:megaphone',
  merge: 'icon-park-outline:merge',
  minus: 'ri:subtract-fill',
  mountain: 'mdi:mountain',
  movement: {
    up: 'ph:trend-up-bold',
    down: 'ph:trend-down-bold',
  },
  notification: 'ic:round-notifications',
  open: 'fluent:open-16-filled',
  openai: 'ri:openai-fill',
  opportunity: 'ic:round-flag',
  people: 'ic:baseline-people',
  person: 'iconamoon:profile-fill',
  phone: 'bi:phone',
  plus: 'material-symbols:add',
  pointer: {
    up: 'ion:caret-up-outline',
    down: 'ion:caret-down-outline',
  },
  push: 'ion:push-outline',
  refresh: 'iconamoon:restart-duotone',
  role: 'eos-icons:cluster-role',
  search: 'ion:search',
  security: 'material-symbols:security',
  send: 'mi:send',
  settings: 'mdi:settings',
  survey: 'ri:survey-line',
  sms: 'material-symbols:sms-rounded',
  switch: 'mi:switch',
  tenure: 'tabler:old',
  transactions: 'bitcoin-icons:transactions-filled',
  totals: 'fluent-mdl2:total',
  upload: 'lucide:upload',
}

export default icons
