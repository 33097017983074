import { useEffect, useRef, useState } from 'react'
import ChartData from '../../model/chart/ChartData'
import ChartType from '../../model/chart/ChartType'
import DensityMapData from '../../model/chart/DensityMapData'
import FullLevelsChartData from '../../model/chart/FullLevelsChartData'
import MultiStatisticData from '../../model/chart/MultiStatisticData'
import StatisticData from '../../model/chart/StatisticData'
import SummitRateChartData from '../../model/chart/SummitRateChartData'
import TimeframeChartData from '../../model/chart/TimeframeChartData'
import EChart from './Echart'
import { LevelsCard } from './levels/LevelsCard'
import DensityMapInputView from './map/DensityMapInputView'
import MultiStatistic from './MultiStatistic'
import Statistic from './Statistic'
import SummitRateChart from './SummitRateChart'
import TimeframeStatistic from './TimeframeStatistic'

export interface MetricChartDisplayProps {
  metricID: string
  type: ChartType
  data: ChartData
  selectedValues?: string[]
  onSelectValue?: (value: string, metricID: string) => void
  state?: any
  onStateChange?: (state: any) => void
}

export default function MetricChartDisplay({
  metricID,
  type,
  data,
  selectedValues,
  onSelectValue,
  state,
  onStateChange,
  ...rest
}: MetricChartDisplayProps) {
  // -- Local state
  const [animation, setAnimation] = useState(true)

  // Animate only on first render
  const firstRender = useRef(true)
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    setAnimation(false)
  }, [selectedValues])

  // -- UI
  const dataErrorMessage = `Invalid data type for chart: ${type}`

  switch (type) {
    case ChartType.statistic:
      if (data instanceof StatisticData) return <Statistic data={data} />
      throw new Error(dataErrorMessage)
    case ChartType.multiStatistic:
      if (data instanceof MultiStatisticData) return <MultiStatistic data={data} />
      throw new Error(dataErrorMessage)
    case ChartType.summitRate:
      if (data instanceof SummitRateChartData) return <SummitRateChart data={data} />
      throw new Error(dataErrorMessage)
    case ChartType.levelsFull:
      if (data instanceof FullLevelsChartData) return <LevelsCard data={data} />
      throw new Error(dataErrorMessage)
    case ChartType.timeframe:
      if (data instanceof TimeframeChartData) return <TimeframeStatistic data={data} />
      throw new Error(dataErrorMessage)
    case ChartType.densityMap:
      if (data instanceof DensityMapData)
        return (
          <DensityMapInputView
            metrics={[
              {
                id: 'member-count',
                name: 'Member Count',
                data: data.densityData,
                suffix: 'Members',
              },
            ]}
            physicalLocations={data.physicalLocations}
            state={state}
            onSelectValue={onSelectValue}
            onStateChange={onStateChange}
          />
        )
      throw new Error(dataErrorMessage)
    default:
      return (
        <EChart
          data={data}
          type={type}
          selectedValues={selectedValues}
          onSelectValue={(value) => onSelectValue?.(value, metricID)}
          animation={animation}
          valueAxis={{
            name: 'Members',
          }}
          {...rest}
        />
      )
  }
}
